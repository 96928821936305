export default class UserFilterInput {
  constructor(data) {
    this.ids = data?.ids ?? []
    this.search = data?.search
    this.withDeleted = data?.withDeleted ?? false
    this.statuses = data?.statuses ?? []
    this.roles = data?.roles ?? []
  }

  static default() {
    return new UserFilterInput()
  }

  static create(data) {
    return new UserFilterInput(data)
  }
}
