import gql from 'graphql-tag'
import apolloClient from '@/plugins/apolo-client'
import LoginProfile from '@/model/profile/LoginProfile'
import axios from 'axios'
import { apiUrl } from '@/utils/ServiceUrls'

export default {
  load() {
    const LOGIN_PROFILE = gql`
      query loginProfile {
        loginProfile {
          id
          record {
            id
            email
            username
            fullName
            role
            permissions
            settings {
              name
              value
            }
            hasImage
            meta {
              isDeleted
              createdAt
              updatedAt
            }
          }
        }
      }
    `

    return apolloClient.query({
      query: LOGIN_PROFILE,
      fetchPolicy: 'network-only'
    }).then((result) => {
      const { loginProfile } = result.data
      const settings = {}
      const { record } = loginProfile
      const profileSettings = record.settings ?? []

      profileSettings.forEach((element) => {
        settings[element.name] = element.value
      })

      return LoginProfile.create({ ...record, settings: settings })
    })
  },

  update(loginProfileUpdateForm) {
    const LOGIN_PROFILE_UPDATE = gql`
      mutation loginProfileUpdate ($input: LoginProfileUpdateInput!) {
        loginProfileUpdate (input: $input) {
          id
          record {
            id
            username
            email
            fullName
            role
            hasImage
            meta {
              isDeleted
              createdAt
              updatedAt
            }
            settings {
              name
              value
              valueType
            }
          }
        }
      }
    `

    const input = {
      fullName: loginProfileUpdateForm.fullName
    }

    return apolloClient.mutate({
      mutation: LOGIN_PROFILE_UPDATE,
      variables: {
        input: input
      }
    }).then((result) => {
      const { data } = result

      if (data.loginProfileUpdate && data.loginProfileUpdate.record) {

        return LoginProfile.create(data.loginProfileUpdate.record)
      } else {
        throw new Error('Something wrong!')
      }
    })
  },

  changePassword(loginProfileChangePassword) {
    console.log('change', loginProfileChangePassword)
    const LOGIN_PROFILE_CHANGE_PASSWORD = gql`
      mutation loginProfileChangePassword ($input: LoginProfileChangePasswordInput!) {
        loginProfileChangePassword (input: $input) {
          id
          record {
            id
            username
            email
            fullName
            role
            hasImage
            meta {
              isDeleted
              createdAt
              updatedAt
            }
          }
        }
      }
    `
    const input = {
      oldPassword: loginProfileChangePassword.oldPassword,
      newPassword: loginProfileChangePassword.newPassword
    }

    return apolloClient.mutate({
      mutation: LOGIN_PROFILE_CHANGE_PASSWORD,
      variables: {
        input: input
      }
    }).then((result) => {
      const { data } = result

      if (data.loginProfileChangePassword && data.loginProfileChangePassword.record) {

        return LoginProfile.create(data.loginProfileChangePassword.record)
      } else {
        throw new Error('Something wrong!')
      }
    })
  },

  closeAccount(loginProfileCloseAccount) {
    const LOGIN_PROFILE_DISMISS = gql`
      mutation loginProfileDismiss ($input: LoginProfileDismissInput!) {
        loginProfileDismiss (input: $input) {
          id
          record {
            id
            email
            username
            fullName
            role
            hasImage
            meta {
              isDeleted
              createdAt
              updatedAt
            }
          }
        }
      }
    `
    const input = {
      password: loginProfileCloseAccount.password
    }

    return apolloClient.mutate({
      mutation: LOGIN_PROFILE_DISMISS,
      variables: {
        input: input
      }
    }).then((result) => {
      const { data } = result

      if (data.loginProfileDismiss && data.loginProfileDismiss.record) {

        return LoginProfile.create(data.loginProfileDismiss.record)
      } else {
        throw new Error('Something wrong!')
      }
    })
  },

  uploadAvatar({ file }) {
    const formData = new FormData()

    formData.append('file', file)

    return axios.post(
      apiUrl('/api/v1/upload/avatar'),
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  },

  loginProfileAvatarDelete() {
    const LOGIN_PROFILE_AVATAR_DELETE = gql`
      mutation loginProfileAvatarDelete {
        loginProfileAvatarDelete {
          id
          record {
            id
            username
            email
            fullName
            role
            meta {
              isDeleted
              createdAt
              updatedAt
            }
            hasImage
            settings {
              name
              value
              valueType
            }
          }
        }
      }
    `

    return apolloClient.mutate({
      mutation: LOGIN_PROFILE_AVATAR_DELETE,
      variables: {}
    }).then((result) => {
      const { data } = result

      if (data.loginProfileAvatarDelete && data.loginProfileAvatarDelete.record) {

        return LoginProfile.create(data.loginProfileAvatarDelete.record)
      } else {
        throw new Error('Something wrong!')
      }
    })
  }
}
