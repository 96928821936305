import Page from '@/model/Page'
import CategoryFilter from '@/model/category/CategoryFilter'
import CategoryService from '@/service/CategoryService'

export const state = {
  items: [],
  filter: CategoryFilter.default(),
  itemsTotalCount: 0,
  page: {
    ...Page.default(),
    size: 30,
    sort: [{ field: 'name', order: 'Asc' }]
  },
  pagesTotal: 0,
  busy: false,
  showAdvancedSearch: false
}

// mutations
export const mutations = {
  SET_FILTER(state, { filter }) {
    state.filter = CategoryFilter.create(filter) ?? CategoryFilter.default()
  },
  SET_ITEMS(state, { items }) {
    state.items = items
  },
  SET_ITEMS_TOTAL_COUNT(state, { count }) {
    state.itemsTotalCount = count ?? 0
  },
  SET_PAGE(state, { page }) {
    state.page = Page.create(page)
  },
  SET_BUSY(state, { busy }) {
    state.busy = busy
  },
  TOGGLE_ADVANCED_SEARCH(state) {
    state.showAdvancedSearch = !state.showAdvancedSearch
  }
}

// getters
export const getters = {}

// actions
export const actions = {
  fetchData({ commit, dispatch }) {
    commit('SET_BUSY', { busy: true })
    Promise.all([dispatch('fetchCategories')])
      .finally(() => commit('SET_BUSY', { busy: false }))
  },
  fetchCategories({ state, commit }) {
    return CategoryService.find(state.filter, state.page)
      .then((result) => {
        commit('SET_ITEMS', { items: result.nodes })
        commit('SET_ITEMS_TOTAL_COUNT', { count: result.totalCount })
      })
  },
  deleteCategory({ commit, dispatch }, { ids }) {
    commit('SET_BUSY', { busy: true })

    return CategoryService.delete({ ids })
      .then(() => dispatch('fetchCategories'))
      .finally(() => commit('SET_BUSY', { busy: false }))
  },
  setIsActive({ commit, dispatch }, { ids, isActive }) {
    commit('SET_BUSY', { busy: true })

    return CategoryService.setIsActive({ ids, isActive })
      .then(() => dispatch('fetchCategories'))
      .finally(() => commit('SET_BUSY', { busy: false }))
  }
}
