const orderStatusMap = {
  New: { value: 'New', text: 'Новый', icon: '', color: 'info' },
  Review: { value: 'Review', text: 'У менеджера', icon: '', color: 'warning' },
  Scheduled: { value: 'Scheduled', text: 'Запланирован', icon: '', color: 'warning' },
  InProgress: { value: 'InProgress', text: 'В работе', icon: '', color: 'warning' },
  Ready: { value: 'Ready', text: 'Собран', icon: '', color: 'warning' },
  Delivery: { value: 'Delivery', text: 'Доставка', icon: '', color: 'warning' },
  Done: { value: 'Done', text: 'Завершён', icon: '', color: 'success' },
  Canceled: { value: 'Canceled', text: 'Отменен', icon: '', color: 'error' }
}

// const orderStatusList = orderStatusMap.entries.map(([key, value]) => value)
const orderStatusList = function () {
  return [
    { value: 'New', text: 'Новый', icon: '', color: 'info' },
    { value: 'Review', text: 'У менеджера', icon: '', color: 'warning' },
    { value: 'Scheduled', text: 'Запланирован', icon: '', color: 'warning' },
    { value: 'InProgress', text: 'В работе', icon: '', color: 'warning' },
    { value: 'Ready', text: 'Собран', icon: '', color: 'warning' },
    { value: 'Delivery', text: 'Доставка', icon: '', color: 'warning' },
    { value: 'Done', text: 'Завершён', icon: '', color: 'success' },
    { value: 'Canceled', text: 'Отменен', icon: '', color: 'error' }
  ]
}

const orderStatusByValue = function (value) {
  return orderStatusMap[value]
}

const orderStatusColor = function (value) {
  return orderStatusMap[value].color
}

const orderStatusTitle = function (value) {
  return orderStatusMap[value].text
}

export { orderStatusList, orderStatusByValue, orderStatusTitle, orderStatusColor }
