export default class TagFilter {
  constructor(data) {
    this.ids = data?.ids ?? []
    this.search = data?.search
  }

  static default() {
    return new TagFilter()
  }

  static create(data) {
    return new TagFilter(data)
  }
}
