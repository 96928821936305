import UserService from '@/service/UserService'
import config from '../../configs'
import axios from 'axios'

export const state = {
  user: null
}

// mutations
export const mutations = {
  SET_USER(state, { user }) {
    state.user = user
  }
}

// getters
export const getters = {}

// actions
export const actions = {
  fetchUser({ state, commit }, { userId }) {
    return UserService.findUserById(userId)
      .then((result) => {
        commit('SET_USER', { user: result })
      })
  },
  updateUser({ state, commit }, { form }) {
    return UserService.userUpdate(form)
      .then((result) => {
        commit('SET_USER', { user: result })
      })
  },
  createUser({ state, commit }, { form }) {
    return UserService.userCreate(form)
      .then((result) => {
        commit('SET_USER', { user: result })
      })
  },

  async uploadAvatar({ commit, dispatch }, { id, file }) {
    return UserService.uploadAvatar({ id, file })
  },

  async userAvatarDelete({ commit, dispatch }, { userId }) {
    return UserService.userAvatarDelete({ userId })
      .then((result) => {
        commit('SET_USER', { user: result })
      })
  },

  changeUserPassword({ state, commit }, { form }) {
    return UserService.userChangePassword(form)
  },

  deleteUser({ state }, { id }) {
    return UserService.userDelete([id])
  }
}
