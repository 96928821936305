export default class ItemUpdateForm {
  constructor(data) {
    this.name = data?.name
    this.description = data?.description
    this.content = data?.content
    this.price = data?.price
    this.priceOld = data?.priceOld
    this.width = data?.width
    this.height = data?.height
    this.length = data?.length
    this.reviewVideo = data?.reviewVideo
    this.isActive = data?.isActive
    this.isInStock = data?.isInStock
    this.isPublic = data?.isPublic
  }

  static create(data) {
    return new ItemUpdateForm(data)
  }

}
