import gql from 'graphql-tag'
import apolloClient from '@/plugins/apolo-client'
import PropFilter from '@/model/props/PropFilter'

export default {
  find(filter, page) {
    const QUERY = gql`
      query props ($filter: PropFilterInput, $page: PageInput) {
          props (filter: $filter, page: $page) {
              totalCount
              nodes {
                  id
                  name
                  sortWeight
                  unitOfMeasure {
                    id
                    name
                  }
              }
          }
      }
    `

    return apolloClient.query({
      query: QUERY,
      fetchPolicy: 'network-only',
      variables: {
        filter: filter,
        page: page
      }
    }).then((result) => {
      const { data } = result

      return data.props
    })
  },

  delete({ ids }) {
    const MUTATION = gql`
      mutation propDelete ($input: PropDeleteInput) {
          propDelete (input: $input) {
              ids
          }
      }
    `

    const input = { ids }

    return apolloClient.mutate({
      mutation: MUTATION,
      variables: {
        input: input
      }
    }).then((result) => {
      const { data } = result

      if (data.propDelete && data.propDelete.ids) {
        return data.propDelete.ids
      } else {
        throw new Error('Something wrong!')
      }
    })
  },

  create({ form }) {
    const MUTATION = gql`
      mutation propCreate ($input: PropCreateInput) {
          propCreate (input: $input) {
              id
              record {
                  id
                  name
                  sortWeight
              }
          }
      }
    `

    const input = {
      ...form,
      sortWeight: parseInt(form.sortWeight)
    }

    return apolloClient.mutate({
      mutation: MUTATION,
      variables: {
        input: input
      }
    }).then((result) => {
      const { data } = result

      if (data.propCreate && data.propCreate.record) {
        return data.propCreate.record
      } else {
        throw new Error('Something wrong!')
      }
    })
  },

  update({ id, form }) {
    const MUTATION = gql`
      mutation propUpdate ($input: PropUpdateInput) {
          propUpdate (input: $input) {
              id
              record {
                  id
                  name
              }
          }
      }
    `

    const input = {
      ...form,
      id,
      sortWeight: parseInt(form.sortWeight)
    }

    return apolloClient.mutate({
      mutation: MUTATION,
      variables: {
        input: input
      }
    }).then((result) => {
      const { data } = result

      if (data.propUpdate && data.propUpdate.record) {
        return data.propUpdate.record
      } else {
        throw new Error('Something wrong!')
      }
    })
  },

  findById({ id }) {
    const QUERY = gql`
      query props ($filter: PropFilterInput, $page: PageInput) {
          props (filter: $filter, page: $page) {
              totalCount
              nodes {
                  id
                  name
                  sortWeight
                  unitOfMeasure {
                    id
                    name
                  }
              }
          }
      }
    `

    const filter = PropFilter.create({ ids: [id] })

    return apolloClient.query({
      query: QUERY,
      fetchPolicy: 'network-only',
      variables: {
        filter
      }
    }).then((result) => {
      const { data } = result

      if (data.props && data.props.nodes && data.props.nodes.length > 0) {
        return data.props.nodes[0]
      }

      throw Error('Prop [' + id + '] not found')
    })
  }
}
