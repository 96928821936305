import Page from '@/model/Page'
import DiscountFilter from '@/model/discount/DiscountFilter'
import DiscountService from '@/service/DiscountService'

export const state = {
  items: [],
  filter: DiscountFilter.default(),
  itemsTotalCount: 0,
  page: {
    ...Page.default(),
    size: 30,
    sort: [{ field: 'id', order: 'Desc' }]
  },
  pagesTotal: 0,
  busy: false,
  showAdvancedSearch: false
}

// mutations
export const mutations = {
  SET_FILTER(state, { filter }) {
    state.filter = DiscountFilter.create(filter) ?? DiscountFilter.default()
  },
  SET_ITEMS(state, { items }) {
    state.items = [... items]
  },
  SET_ITEMS_TOTAL_COUNT(state, { count }) {
    state.itemsTotalCount = count ?? 0
  },
  SET_PAGE(state, { page }) {
    state.page = Page.create(page)
  },
  SET_BUSY(state, { busy }) {
    state.busy = busy
  },
  TOGGLE_ADVANCED_SEARCH(state) {
    state.showAdvancedSearch = !state.showAdvancedSearch
  }
}

// getters
export const getters = {}

// actions
export const actions = {
  fetchData({ commit, dispatch }) {
    commit('SET_BUSY', { busy: true })
    Promise.all([dispatch('fetchItems')])
      .finally(() => commit('SET_BUSY', { busy: false }))
  },

  fetchItems({ state, commit }) {
    return DiscountService.find(state.filter, state.page)
      .then((result) => {
        commit('SET_ITEMS', { items: result.nodes })
        commit('SET_ITEMS_TOTAL_COUNT', { count: result.totalCount })
      })
  },

  deleteDiscount({ commit, dispatch }, { ids }) {
    commit('SET_BUSY', { busy: true })

    return DiscountService.delete({ ids })
      .then(() => dispatch('fetchItems'))
      .finally(() => commit('SET_BUSY', { busy: false }))
  }
}
