import OrderService from '@/service/OrderService'
import apolloClient from '@/plugins/apolo-client'
import gql from 'graphql-tag'

export const state = {
  id: null,
  item: {},
  users: [],
  busy: false
}

// mutations
export const mutations = {
  SET_ITEM_ID(state, { id }) {
    state.id = id
  },
  SET_ITEM(state, { item }) {
    state.item = item
  },
  SET_USERS(state, { users }) {
    state.users = users
  },
  SET_BUSY(state, { busy }) {
    state.busy = busy
  }
}

// actions
export const actions = {
  fetchData({ commit, dispatch }, { id }) {
    commit('SET_BUSY', { busy: true })
    commit('SET_ITEM_ID', { id })

    const query = gql`
      query orderManagePage ($orderFilter: OrderFilterInput, $usersFilter: UserFilterInput, $usersPage: PageInput) {
        orders (filter: $orderFilter) {
          totalCount
          nodes {
            id
            code
            deliveryType
            deliveryDate
            deliveryTimeSlot
            shippingAt
            manager {
              id
            }
            worker {
              id
            }
          }
        }
        users (filter: $usersFilter, page: $usersPage) {
          totalCount
          nodes {
            id
            role
            fullName
            email
          }
        }
      }
    `
    const orderFilter = { ids: [state.id] }
    const usersFilter = { statuses: ['Active'], roles: ['Florist','Manager','Admin'] }
    const usersPage = { size: 1000, sort: { field: 'fullName', order: 'Asc' }   }

    return apolloClient.query({
      query,
      fetchPolicy: 'network-only',
      variables: { orderFilter, usersFilter, usersPage }
    }).then((result) => {
      const { orders, users } = result.data

      if (orders && orders.nodes && orders.nodes.length > 0) {
        commit('SET_ITEM', { item: orders.nodes[0] })
      }
      else {
        throw Error('Order [' + id + '] not found')
      }

      if (users) {
        commit('SET_USERS', { users: users.nodes })
      }

      return orders.nodes[0]
    })
      .finally(() => commit('SET_BUSY', { busy: false }))
  },

  updateItem({ state, commit }, { form }) {
    commit('SET_BUSY', { busy: true })

    return OrderService.manageOrder({ id: state.id, form })
      .then((result) => {
        commit('SET_ITEM', { item: result })
      })
      .finally(() => commit('SET_BUSY', { busy: false }))
  }
}
