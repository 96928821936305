import OrderService from '@/service/OrderService'

export const state = {
  busy: false,
  order: {
    code: null
  },
  orderId: null
}

// mutations
export const mutations = {
  SET_BUSY(state, { busy }) {
    state.busy = busy
  },
  SET_ORDER_ID(state, { orderId }) {
    state.orderId = orderId
  },
  SET_ORDER(state, { order }) {
    state.order = order  }
}

// actions
export const actions = {
  fetchData({ commit, dispatch }, { orderId }) {
    commit('SET_ORDER_ID', { orderId })

    return Promise.all([dispatch('fetchOrder')])
      .finally(() => commit('SET_BUSY', { busy: false }))
  },

  fetchOrder({ state, commit }) {
    return OrderService.findById({ id: state.orderId })
      .then((result) => {
        commit('SET_ORDER', { order: result })
      })
  },

  createOrderLine({ state, commit }, { form }) {
    commit('SET_BUSY', { busy: true })

    return OrderService.createLine(state.orderId, { form })
      .finally(() => commit('SET_BUSY', { busy: false }))
  },

  createOrderLineWithNewItem({ state, commit }, { form }) {
    commit('SET_BUSY', { busy: true })

    return OrderService.createLineWithNewItem(state.orderId, { form })
      .finally(() => commit('SET_BUSY', { busy: false }))
  }
}
