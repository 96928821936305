import gql from 'graphql-tag'
import apolloClient from '@/plugins/apolo-client'
import DiscountFilter from '@/model/discount/DiscountFilter'

export default {
  find(filter, page) {
    const QUERY = gql`
      query discounts ($filter: DiscountFilterInput, $page: PageInput) {
        discounts (filter: $filter, page: $page) {
          totalCount
          nodes {
            id
            promotion
            discountTarget
            dateStart
            dateEnd
            discountValueType
            discountValue
            meta {
              isDeleted
              createdAt
              updatedAt
            }
          }
        }
      }
    `

    return apolloClient.query({
      query: QUERY,
      fetchPolicy: 'network-only',
      variables: {
        filter: filter,
        page: page
      }
    }).then((result) => {
      const { data } = result

      return data.discounts
    })
  },

  delete({ ids }) {
    const MUTATION = gql`
      mutation discountDelete ($input: DiscountDeleteInput) {
        discountDelete (input: $input) {
          ids
        }
      }
    `

    const input = { ids }

    return apolloClient.mutate({
      mutation: MUTATION,
      variables: {
        input: input
      }
    }).then((result) => {
      const { data } = result

      if (data.discountDelete && data.discountDelete.ids) {
        return data.discountDelete.ids
      } else {
        throw new Error('Something wrong!')
      }
    })
  },

  create({ form }) {
    const MUTATION = gql`
      mutation discountCreate ($input: DiscountCreateInput) {
        discountCreate (input: $input) {
          id
          record {
            id
            promotion
            discountTarget
            dateStart
            dateEnd
            discountValueType
            discountValue
            meta {
              isDeleted
              createdAt
              updatedAt
            }
          }
        }
      }
    `

    const input = {
      ...form
    }

    return apolloClient.mutate({
      mutation: MUTATION,
      variables: {
        input: input
      }
    }).then((result) => {
      const { data } = result

      if (data.discountCreate && data.discountCreate.record) {
        return data.discountCreate.record
      } else {
        throw new Error('Something wrong!')
      }
    })
  },

  update({ id, form }) {
    const MUTATION = gql`
      mutation discountUpdate ($input: DiscountUpdateInput) {
        discountUpdate (input: $input) {
          id
          record {
            id
            promotion
            discountTarget
            dateStart
            dateEnd
            discountValueType
            discountValue
            meta {
              isDeleted
              createdAt
              updatedAt
            }
          }
        }
      }
    `

    const input = {
      id,
      ...form
    }

    return apolloClient.mutate({
      mutation: MUTATION,
      variables: {
        input: input
      }
    }).then((result) => {
      const { data } = result

      if (data.discountUpdate && data.discountUpdate.record) {
        return data.discountUpdate.record
      } else {
        throw new Error('Something wrong!')
      }
    })
  },

  findById({ id }) {
    const QUERY = gql`
      query discounts ($filter: DiscountFilterInput, $page: PageInput) {
        discounts (filter: $filter, page: $page) {
          totalCount
          nodes {
            id
            promotion
            discountTarget
            dateStart
            dateEnd
            discountValueType
            discountValue
            meta {
              isDeleted
              createdAt
              updatedAt
            }
          }
        }
      }
    `

    const filter = DiscountFilter.create({ ids: [id] })

    return apolloClient.query({
      query: QUERY,
      fetchPolicy: 'network-only',
      variables: {
        filter
      }
    }).then((result) => {
      const { data } = result

      if (data.discounts && data.discounts.nodes && data.discounts.nodes.length > 0) {
        return data.discounts.nodes[0]
      }

      throw Error('Discount [' + id + '] not found')
    })
  }
}
