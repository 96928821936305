import Page from '@/model/Page'
import OrderService from '@/service/OrderService'
import OrderFilter from '@/model/order/OrderFilter'

export const state = {
  items: [],
  filter: OrderFilter.create({
    orderStatus: ['New','Review','Scheduled','InProgress','Ready','Delivery']
  }),
  itemsTotalCount: 0,
  page: {
    ...Page.default(),
    size: 30,
    sort: [{ field: 'code', order: 'Desc' }]
  },
  pagesTotal: 0,
  busy: false,
  showAdvancedSearch: false
}

// mutations
export const mutations = {
  SET_FILTER(state, { filter }) {
    state.filter = OrderFilter.create(filter) ?? OrderFilter.default()
  },
  SET_ITEMS(state, { items }) {
    state.items = items
  },
  SET_ITEMS_TOTAL_COUNT(state, { count }) {
    state.itemsTotalCount = count ?? 0
  },
  SET_PAGE(state, { page }) {
    state.page = Page.create(page)
  },
  SET_BUSY(state, { busy }) {
    state.busy = busy
  },
  TOGGLE_ADVANCED_SEARCH(state) {
    state.showAdvancedSearch = !state.showAdvancedSearch
  }
}

// getters
export const getters = {}

// actions
export const actions = {
  fetchData({ commit, dispatch }) {
    commit('SET_BUSY', { busy: true })
    Promise.all([dispatch('fetchItems')])
      .finally(() => commit('SET_BUSY', { busy: false }))
  },

  fetchItems({ state, commit }) {
    return OrderService.find(state.filter, state.page)
      .then((result) => {
        commit('SET_ITEMS', { items: result.nodes })
        commit('SET_ITEMS_TOTAL_COUNT', { count: result.totalCount })
      })
  },

  deleteItem({ commit, dispatch }, { ids }) {
    commit('SET_BUSY', { busy: true })

    return OrderService.delete({ ids })
      .then(() => dispatch('fetchItems'))
      .finally(() => commit('SET_BUSY', { busy: false }))
  },

  setOrderStatus({ commit, dispatch }, { ids, orderStatus }) {
    commit('SET_BUSY', { busy: true })

    return OrderService.setStatus({ ids, orderStatus })
      .then(() => dispatch('fetchItems'))
      .finally(() => commit('SET_BUSY', { busy: false }))
  }
}
