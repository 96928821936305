import ItemService from '@/service/ItemService'

export const state = {
  orderId: null,
  id: null,
  item: {
    code: null,
    name: null,
    image: null,
    images: []
  },
  busy: false
}

// mutations
export const mutations = {
  SET_ORDER_ID(state, { orderId }) {
    console.log('orderId',orderId)
    state.orderId = orderId
  },
  SET_ITEM_ID(state, { itemId }) {
    console.log('itemId',itemId)
    state.id = itemId
  },
  SET_ITEM(state, { item }) {
    state.item = item
  },
  SET_BUSY(state, { busy }) {
    state.busy = busy
  }
}

// getters
export const getters = {
  itemId: (state) => state.id
}

// actions
export const actions = {
  fetchData({ commit, dispatch }) {
    commit('SET_BUSY', { busy: true })

    return Promise.all([dispatch('fetchItem')])
      .finally(() => commit('SET_BUSY', { busy: false }))
  },

  fetchItem({ state, commit }) {
    return ItemService.findById({ id: state.id })
      .then((result) => {
        commit('SET_ITEM', { item: result })
      })
  },

  updateItem({ state, commit }, { form }) {
    commit('SET_BUSY', { busy: true })

    return ItemService.update({ id: state.id, form })
      .then((result) => {
        commit('SET_ITEM', { item: result })
      })
      .finally(() => commit('SET_BUSY', { busy: false }))
  },

  uploadImage({ state, commit, dispatch }, { file }) {
    commit('SET_BUSY', { busy: true })

    return ItemService.uploadImage({ id: state.id, file })
      .then(() => dispatch('fetchItem'))
      .finally(() => commit('SET_BUSY', { busy: false }))
  },

  deleteImages({ commit, dispatch }, { itemImageIds }) {
    commit('SET_BUSY', { busy: true })

    return ItemService.deleteImages({ itemImageIds })
      .then(() => dispatch('fetchItem'))
      .finally(() => commit('SET_BUSY', { busy: false }))
  },

  setMainImage({ commit, dispatch }, { itemImageId }) {
    commit('SET_BUSY', { busy: true })

    return ItemService.setMainImage({ itemImageId })
      .then(() => dispatch('fetchItem'))
      .finally(() => commit('SET_BUSY', { busy: false }))
  }
}
