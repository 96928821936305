import ItemService from '@/service/ItemService'
import CategoryService from '@/service/CategoryService'
import ItemCategoryService from '@/service/ItemCategoryService'

export const state = {
  id: null,
  item: {
    code: null
  },
  categories: [],
  busy: false
}

// mutations
export const mutations = {
  SET_ITEM_ID(state, { id }) {
    state.id = id
  },
  SET_ITEM(state, { item }) {
    state.item = item
  },
  SET_CATEGORIES(state, { categories }) {
    state.categories = categories
  },
  SET_BUSY(state, { busy }) {
    state.busy = busy
  }
}

// getters
export const getters = {
  itemId: (state) => state.id
}

// actions
export const actions = {
  fetchData({ commit, dispatch }, { id }) {
    commit('SET_BUSY', { busy: true })
    commit('SET_ITEM_ID', { id })

    return Promise.all([dispatch('fetchItem'), dispatch('fetchCategories')])
      .finally(() => commit('SET_BUSY', { busy: false }))
  },
  fetchItem({ state, commit }) {
    return ItemService.findById({ id: state.id })
      .then((result) => {
        commit('SET_ITEM', { item: result })
      })
  },
  fetchCategories({ state, commit }) {
    return CategoryService.find({}, { page: 0, size: 1000, sort: [{ field: 'name' }] })
      .then((result) => {
        commit('SET_CATEGORIES', { categories: result.nodes })
      })
  },
  updateItemCategories({ state, commit }, { categoryIds }) {
    commit('SET_BUSY', { busy: true })

    return ItemCategoryService.updateItemCategories({ id: state.id, categoryIds })
      .finally(() => commit('SET_BUSY', { busy: false }))
  }

}
