import PropsService from '@/service/PropsService'
import UnitOfMeasureService from '@/service/UnitOfMeasureService'

export const state = {
  id: null,
  item: null,
  unitOfMeasures: [],
  busy: false
}

// mutations
export const mutations = {
  SET_ITEM_ID(state, { id }) {
    state.id = id
  },
  SET_ITEM(state, { item }) {
    state.item = item
  },
  SET_UOM(state, { unitOfMeasures }) {
    state.unitOfMeasures = unitOfMeasures
  },
  SET_BUSY(state, { busy }) {
    state.busy = busy
  }
}

// getters
export const getters = {
  itemId: (state) => state.id
}

// actions
export const actions = {
  fetchData({ commit, dispatch }, { id }) {
    commit('SET_BUSY', { busy: true })
    commit('SET_ITEM_ID', { id })

    return Promise.all([dispatch('fetchProp'),dispatch('fetchUnitOfMeasures')])
      .finally(() => commit('SET_BUSY', { busy: false }))
  },
  fetchProp({ state, commit }) {
    return PropsService.findById({ id: state.id })
      .then((result) => {
        commit('SET_ITEM', { item: result })
      })
  },
  fetchUnitOfMeasures({ state, commit }) {
    return UnitOfMeasureService.find({}, { page: 0, size: 1000, sort: [{ field: 'name' }] })
      .then((result) => {
        commit('SET_UOM', { unitOfMeasures: result.nodes })
      })
  },
  updateProp({ state, commit }, { form }) {
    commit('SET_BUSY', { busy: true })

    return PropsService.update({ id: state.id, form })
      .then((result) => {
        commit('SET_ITEM', { item: result })
      })
      .finally(() => commit('SET_BUSY', { busy: false }))
  }
}
