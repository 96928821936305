import Page from '@/model/Page'
import UnitOfMeasureService from '@/service/UnitOfMeasureService'
import UnitOfMeasureFilter from '@/model/uom/UnitOfMeasureFilter'

export const state = {
  items: [],
  filter: UnitOfMeasureFilter.default(),
  itemsTotalCount: 0,
  page: {
    ...Page.default(),
    size: 10,
    sort: [{ field: 'name', order: 'Asc' }]
  },
  pagesTotal: 0,
  busy: false
}

// mutations
export const mutations = {
  SET_FILTER(state, { filter }) {
    state.filter = UnitOfMeasureFilter.create(filter) ?? UnitOfMeasureFilter.default()
  },
  SET_ITEMS(state, { items }) {
    state.items = items
  },
  SET_ITEMS_TOTAL_COUNT(state, { count }) {
    state.itemsTotalCount = count ?? 0
  },
  SET_PAGE(state, { page }) {
    state.page = Page.create(page)
  },
  SET_BUSY(state, { busy }) {
    state.busy = busy
  }
}

// getters
export const getters = {}

// actions
export const actions = {
  fetchData({ commit, dispatch }) {
    commit('SET_BUSY', { busy: true })
    Promise.all([dispatch('fetchUnitOfMeasures')])
      .finally(() => commit('SET_BUSY', { busy: false }))
  },
  fetchUnitOfMeasures({ state, commit }) {
    return UnitOfMeasureService.find(state.filter, state.page)
      .then((result) => {
        commit('SET_ITEMS', { items: result.nodes })
        commit('SET_ITEMS_TOTAL_COUNT', { count: result.totalCount })
      })
  },
  deleteUnitOfMeasures({ commit, dispatch }, { ids }) {
    commit('SET_BUSY', { busy: true })

    return UnitOfMeasureService.delete({ ids })
      .then(() => dispatch('fetchUnitOfMeasures'))
      .finally(() => commit('SET_BUSY', { busy: false }))
  }
}
