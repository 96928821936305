import ItemService from '@/service/ItemService'

export const state = {
  busy: false
}

// mutations
export const mutations = {
  SET_BUSY(state, { busy }) {
    state.busy = busy
  }
}

// getters
export const getters = {}

// actions
export const actions = {
  createItem({ state, commit }, { form }) {
    console.log(form)
    commit('SET_BUSY', { busy: true })

    return ItemService.create({ form })
      .finally(() => commit('SET_BUSY', { busy: false }))
  }
}
