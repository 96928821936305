import Meta from '@/model/Meta'

export default class LoginProfile {
  constructor(data) {
    this.id = data.id
    this.username = data.username
    this.email = data.email
    this.fullName = data.fullName
    this.role = data.role
    this.permissions = data.permissions
    this.meta = Meta.create(data.meta)
    this.settings = data.settings ?? []
    this.hasImage = data.hasImage ?? false
  }

  static create(data) {
    return new LoginProfile(data)
  }
}
