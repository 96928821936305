export default class DiscountFilter {
  constructor(data) {
    this.ids = data?.ids ?? []
    this.search = data?.search
    this.startDate = data?.startDate
    this.endDate = data?.endDate
  }

  static default() {
    return new DiscountFilter()
  }

  static create(data) {
    return new DiscountFilter(data)
  }
}
