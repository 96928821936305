import gql from 'graphql-tag'
import apolloClient from '@/plugins/apolo-client'
import ItemFilter from '@/model/item/ItemFilter'
import axios from 'axios'
import { apiUrl } from '@/utils/ServiceUrls'

export default {
  find(filter, page) {
    const QUERY = gql`
      query items ($filter: ItemFilterInput, $page: PageInput) {
        items (filter: $filter, page: $page) {
          totalCount
          nodes {
            id
            name
            description
            content
            image
            code
            price
            priceOld
            width
            height
            length
            codeBs
            reviewVideo
            isActive
            isInStock
            isPublic
            exportYandexMarket
            meta {
              isDeleted
              createdAt
              updatedAt
            }
            categories {
              id
              name
              image
              isActive
              meta {
                isDeleted
                createdAt
                updatedAt
              }
            }
            props {
              id
              name
              sortWeight
              value
            }
            images {
              id
              image
              isMain
            }
            tags {
              id
              name
            }
          }
        }
      }
    `

    return apolloClient.query({
      query: QUERY,
      fetchPolicy: 'network-only',
      variables: {
        filter: { ... filter },
        page: page
      }
    }).then((result) => {
      const { data } = result

      return data.items
    })
  },

  findForAutocomplete(filter, page) {
    const QUERY = gql`
      query items ($filter: ItemFilterInput, $page: PageInput) {
        items (filter: $filter, page: $page) {
          totalCount
          nodes {
            id
            name
            image
            code
            isActive
            price
          }
        }
      }
    `

    return apolloClient.query({
      query: QUERY,
      fetchPolicy: 'network-only',
      variables: {
        filter: filter,
        page: page
      }
    }).then((result) => {
      const { data } = result

      return data.items
    })
  },

  delete({ ids }) {
    const MUTATION = gql`
      mutation itemDelete ($input: ItemDeleteInput) {
        itemDelete (input: $input) {
          ids
        }
      }
    `

    const input = { ids }

    return apolloClient.mutate({
      mutation: MUTATION,
      variables: {
        input: input
      }
    }).then((result) => {
      const { data } = result

      if (data.itemDelete && data.itemDelete.ids) {
        return data.itemDelete.ids
      } else {
        throw new Error('Something wrong!')
      }
    })
  },

  create({ form }) {
    const MUTATION = gql`
      mutation itemCreate ($input: ItemCreateInput) {
        itemCreate (input: $input) {
          id
        }
      }
    `

    const input = {
      name: form.name
    }

    return apolloClient.mutate({
      mutation: MUTATION,
      variables: {
        input: input
      }
    }).then((result) => {
      const { data } = result

      if (data.itemCreate && data.itemCreate.id) {
        return data.itemCreate.id
      } else {
        throw new Error('Something wrong!')
      }
    })
  },

  findById({ id }) {
    const QUERY = gql`
      query items ($filter: ItemFilterInput, $page: PageInput) {
        items (filter: $filter, page: $page) {
          totalCount
          nodes {
            id
            name
            description
            content
            image
            code
            price
            priceOld
            width
            height
            length
            codeBs
            reviewVideo
            isActive
            isInStock
            isPublic
            exportYandexMarket
            meta {
              isDeleted
              createdAt
              updatedAt
            }
            categories {
              id
              name
              image
              isActive
              meta {
                isDeleted
                createdAt
                updatedAt
              }
            }
            props {
              id
              name
              sortWeight
              value
              unitOfMeasure {
                name
              }
            }
            images {
              id
              image
              isMain
            }
            tags {
              id
              name
            }
            yandexMarket {
              name
              typePrefix
              vendor
              model
              description
              shortDescription
              isDataGenerated
            }
          }
        }
      }
    `

    const filter = ItemFilter.create({ ids: [id] })

    return apolloClient.query({
      query: QUERY,
      fetchPolicy: 'network-only',
      variables: {
        filter
      }
    }).then((result) => {
      const { data } = result

      if (data.items && data.items.nodes && data.items.nodes.length > 0) {
        return data.items.nodes[0]
      }

      throw Error('Item [' + id + '] not found')
    })
  },

  update({ id, form }) {
    const MUTATION = gql`
      mutation itemUpdate ($input: ItemUpdateInput) {
        itemUpdate (input: $input) {
          id
          record {
            id
            name
            description
            content
            image
            code
            price
            width
            height
            length
            codeBs
            reviewVideo
            isActive
            categories {
              id
              name
              image
              isActive
              meta {
                isDeleted
                createdAt
                updatedAt
              }
            }
            props {
              id
              name
              sortWeight
              value
            }
            images {
              id
              image
              isMain
            }
            tags {
              id
              name
            }
          }
        }
      }
    `

    const input = {
      ...form,
      id
    }

    return apolloClient.mutate({
      mutation: MUTATION,
      variables: {
        input: input
      }
    }).then((result) => {
      const { data } = result

      if (data.itemUpdate && data.itemUpdate.record) {
        return data.itemUpdate.record
      } else {
        throw new Error('Something wrong!')
      }
    })
  },

  setIsActive({ ids, isActive }) {
    const MUTATION = gql`
      mutation itemSetActive ($input: ItemSetActiveInput) {
        itemSetActive (input: $input) {
          ids
        }
      }
    `

    const input = { ids, isActive }

    return apolloClient.mutate({
      mutation: MUTATION,
      variables: {
        input: input
      }
    }).then((result) => {
      const { data } = result

      if (data.itemSetActive && data.itemSetActive.ids) {
        return data.itemSetActive.ids
      } else {
        throw new Error('Something wrong!')
      }
    })

  },

  setInStock({ ids, isInStock }) {
    const MUTATION = gql`
      mutation itemSetInStock ($input: ItemSetInStockInput) {
        itemSetInStock (input: $input) {
          ids
        }
      }
    `

    const input = { ids, isInStock }

    return apolloClient.mutate({
      mutation: MUTATION,
      variables: {
        input: input
      }
    }).then((result) => {
      const { data } = result

      if (data.itemSetInStock && data.itemSetInStock.ids) {
        return data.itemSetInStock.ids
      } else {
        throw new Error('Something wrong!')
      }
    })

  },

  uploadImage({ id, file }) {
    const formData = new FormData()

    formData.append('files', file)

    return axios.post(
      apiUrl(`/api/v1/upload/item/${id}`),
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  },

  deleteImages({ itemImageIds }) {
    const MUTATION = gql`
      mutation itemImageDelete ($input: ItemImageDeleteInput!) {
        itemImageDelete (input: $input) {
          ids
        }
      }
    `

    const input = { itemImageIds }

    return apolloClient.mutate({
      mutation: MUTATION,
      variables: {
        input: input
      }
    }).then((result) => {
      const { data } = result

      if (data.itemImageDelete && data.itemImageDelete.ids) {
        return data.itemImageDelete.ids
      } else {
        throw new Error('Something wrong!')
      }
    })
  },

  setMainImage({ itemImageId }) {
    const MUTATION = gql`
      mutation itemImageSetMain ($input: ItemImageSetMainInput) {
        itemImageSetMain (input: $input) {
          id
        }
      }
    `

    const input = { itemImageId }

    return apolloClient.mutate({
      mutation: MUTATION,
      variables: {
        input: input
      }
    }).then((result) => {
      const { data } = result

      if (data.itemImageSetMain && data.itemImageSetMain.id) {
        return data.itemImageSetMain.id
      } else {
        throw new Error('Something wrong!')
      }
    })
  }
}
