import gql from 'graphql-tag'
import apolloClient from '@/plugins/apolo-client'

export default {
  updateItemCategories({ id, categoryIds }) {
    const MUTATION = gql`
      mutation itemSetCategories ($input: ItemSetCategoriesInput) {
        itemSetCategories (input: $input) {
          ids
        }
      }
    `

    const input = { id, categoryIds }

    return apolloClient.mutate({
      mutation: MUTATION,
      variables: {
        input: input
      }
    }).then((result) => {
      const { data } = result

      if (data.itemSetCategories && data.itemSetCategories.ids) {
        return data.itemSetCategories.ids
      } else {
        throw new Error('Something wrong!')
      }
    })
  }
}
