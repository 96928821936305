import gql from 'graphql-tag'
import apolloClient from '@/plugins/apolo-client'
import LoginProfile from '@/model/profile/LoginProfile'

export default {
  load() {
    const SETTINGS = gql`
      query settings ($filter: SettingsFilterInput) {
          settings (filter: $filter) {
              totalCount
              nodes {
                  id
                  name
                  value
              }
          }
      }
    `
    const filter = {}

    return apolloClient.query({
      query: SETTINGS,
      fetchPolicy: 'network-only',
      variables: {
        filter: filter
      }
    }).then((result) => {
      const { data } = result
      const settings = {}

      data.settings.nodes.forEach((element) => {
        settings[element.name] = element.value
      })

      return settings
    })
  },

  update(settings) {
    const SETTINGS_UPDATE = gql`
      mutation settingsUpdate ($input: [SettingsUpdateInput!]) {
          settingsUpdate (input: $input) {
              records {
                  id
                  name
                  value
                  valueType
              }
          }
      }
    `

    const input = Object.entries(settings).map(([k, v]) => {
      return {
        name: k,
        value: v
      }
    })

    return apolloClient.mutate({
      mutation: SETTINGS_UPDATE,
      variables: {
        input: input
      }
    }).then((result) => {
      const { data } = result

      if (data.settingsUpdate && data.settingsUpdate.records) {

        return data.settingsUpdate.records
      } else {
        throw new Error('Something wrong!')
      }
    })
  }
}
