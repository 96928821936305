export default [{
  text: '',
  items: [
    { icon: 'mdi-view-dashboard-outline', key: 'menu.dashboard', text: 'Дашбоард', link: '/' }
  ]
}, {
  text: 'Главное',
  key: 'menu.test',
  acl: { type: 'acl', id: 'User' },
  items: [
    { icon: 'mdi-calendar-month-outline', key: 'menu.calendar', text: 'Календарь', link: { name: 'order-calendar' } },
    { icon: 'mdi-text-box-multiple-outline', key: 'menu.order', text: 'Заказы', link: { name: 'order.list' } },
    { icon: 'mdi-flower-outline', key: 'menu.item', text: 'Товары', link: { name: 'item.list' } },
    { icon: 'mdi-tag-outline', key: 'menu.tag', text: 'Теги', link: { name: 'tag.list' } },
    { icon: 'mdi-file-tree-outline', key: 'menu.category', text: 'Категории', link: { name: 'category.list' } },
    { icon: 'mdi-vector-intersection', key: 'menu.props', text: 'Свойства Товара', link: { name: 'props.list' } },
    { icon: 'mdi-decimal', key: 'menu.uom', text: 'Единицы измерения', link: { name: 'uom.list' } },
    {
      icon: 'mdi-sale-outline', key: 'menu.discount.levels', text: 'Скидки',
      items: [
        { text: 'Программы', key: 'menu.discount.list', link: { name: 'discount.list' } },
        { text: 'Промокоды', key: 'menu.discount-code.list', link: { name: 'discount-code.list' } }
      ]
    },
    {
      icon: 'mdi-database-export-outline', key: 'menu.export.levels', text: 'Экспорт',
      items: [
        { text: 'Yandex Market', key: 'menu.export.yandex-market', link: { name: 'export.yandex-market' } },
        { text: 'Google Merchant', key: 'menu.export.google-merchant', link: { name: 'export.google-merchant' } }
      ]
    }
  ]
}, {
  text: 'Администрирование',
  key: 'menu.admin',
  acl: { type: 'acl', id: 'Manager' },
  items: [
    {
      icon: 'mdi-account-multiple-outline',
      key: 'menu.admin.users',
      text: 'Пользователи',
      link: { name: 'users.list' }
    },
    {
      icon: 'mdi-store-outline',
      key: 'menu.category',
      text: 'Магазины',
      link: { name: 'shop.list' }
    },
    {
      icon: 'mdi-cog-outline',
      key: 'menu.admin.settings',
      text: 'Настройки',
      link: { name: 'settings.settings' },
      acl: { type: 'acl', id: 'Admin' }
    }
  ]
}]
