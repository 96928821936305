import gql from 'graphql-tag'
import apolloClient from '@/plugins/apolo-client'
import DiscountFilter from '@/model/discount/DiscountFilter'
import DiscountCodeFilter from '@/model/discount/DiscountCodeFilter'

export default {
  find(filter, page) {
    const QUERY = gql`
      query discountCodes ($filter: DiscountCodeFilterInput, $page: PageInput) {
        discountCodes (filter: $filter, page: $page) {
          totalCount
          nodes {
            id
            code
            issued
            inStock
            discount {
              id
              promotion
              dateStart
              dateEnd
              discountValueType
              discountValue
              discountTarget
            }
          }
        }
      }
    `

    return apolloClient.query({
      query: QUERY,
      fetchPolicy: 'network-only',
      variables: {
        filter: filter,
        page: page
      }
    }).then((result) => {
      const { data } = result

      return data.discountCodes
    })
  },

  delete({ ids }) {
    const MUTATION = gql`
      mutation discountCodeDelete ($input: DiscountCodeDeleteInput) {
        discountCodeDelete (input: $input) {
          ids
        }
      }
    `

    const input = { ids }

    return apolloClient.mutate({
      mutation: MUTATION,
      variables: {
        input: input
      }
    }).then((result) => {
      const { data } = result

      if (data.discountCodeDelete && data.discountCodeDelete.ids) {
        return data.discountCodeDelete.ids
      } else {
        throw new Error('Something wrong!')
      }
    })
  },

  create({ form }) {
    const MUTATION = gql`
      mutation discountCodeCreate ($input: DiscountCodeCreateInput) {
        discountCodeCreate (input: $input) {
          record {
            id
          }
        }
      }
    `

    const input = {
      ...form
    }

    return apolloClient.mutate({
      mutation: MUTATION,
      variables: {
        input: input
      }
    }).then((result) => {
      const { data } = result

      if (data.discountCodeCreate && data.discountCodeCreate.record) {
        return data.discountCodeCreate.record
      } else {
        throw new Error('Something wrong!')
      }
    })
  },

  update({ id, form }) {
    const MUTATION = gql`
      mutation discountCodeUpdate ($input: DiscountCodeUpdateInput) {
        discountCodeUpdate (input: $input) {
          id
        }
      }
    `

    const input = {
      id,
      ...form
    }

    return apolloClient.mutate({
      mutation: MUTATION,
      variables: {
        input: input
      }
    }).then((result) => {
      const { data } = result

      if (data.discountCodeUpdate && data.discountCodeUpdate.id) {
        return data.discountCodeUpdate.id
      } else {
        throw new Error('Something wrong!')
      }
    })
  },

  findById({ id }) {
    const QUERY = gql`
      query discountCodes ($filter: DiscountCodeFilterInput, $page: PageInput) {
        discountCodes (filter: $filter, page: $page) {
          totalCount
          nodes {
            id
            code
            issued
            inStock
            discount {
              id
              promotion
            }
          }
        }
      }
    `

    const filter = DiscountCodeFilter.create({ ids: [id] })

    return apolloClient.query({
      query: QUERY,
      fetchPolicy: 'network-only',
      variables: {
        filter
      }
    }).then((result) => {
      const { data } = result

      if (data.discountCodes && data.discountCodes.nodes && data.discountCodes.nodes.length > 0) {
        return data.discountCodes.nodes[0]
      }

      throw Error('Discount [' + id + '] not found')
    })
  },

  generate({ form }) {
    const MUTATION = gql`
      mutation discountCodeGenerate ($input: DiscountCodeGenerateInput) {
        discountCodeGenerate (input: $input) {
          count
        }
      }
    `

    const input = {
      ...form
    }

    return apolloClient.mutate({
      mutation: MUTATION,
      variables: {
        input: input
      }
    }).then((result) => {
      const { data } = result

      if (data.discountCodeGenerate && data.discountCodeGenerate.count) {
        return data.discountCodeGenerate.count
      } else {
        throw new Error('Something wrong!')
      }
    })
  }

}
