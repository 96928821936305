import Page from '@/model/Page'
import UserService from '@/service/UserService'
import UserFilterInput from '@/model/user/UserFilterInput'

export const state = {
  users: {},
  usersTotalCount: 0,
  filter: UserFilterInput.default(),
  page: {
    ...Page.default(),
    size: 10,
    sort: [{ field: 'meta.createdAt', order: 'Desc' }]
  },
  showAdvancedSearch: false
}

// mutations
export const mutations = {
  SET_USERS(state, { users }) {
    state.users = users ?? []
  },
  SET_USERS_TOTAL_COUNT(state, { count }) {
    state.usersTotalCount = count ?? 0
  },
  SET_FILTER(state, { filter }) {
    state.filter = UserFilterInput.create(filter)
  },
  SET_PAGE(state, { page }) {
    state.page = Page.create(page)
  },
  TOGGLE_ADVANCED_SEARCH(state) {
    state.showAdvancedSearch = !state.showAdvancedSearch
  }
}

// getters
export const getters = {
  userList: (state) => state.users,
  userListTotalCount: (state) => state.usersTotalCount
  // userListPage: (state) => state.page.page,
  // userListPerPage: (state) => state.page.size,
  // userListPageCount: (state) => Math.ceil((state.users?.totalCount ?? 0) / state.page.size)
}

// actions
export const actions = {
  fetchUsers({ state, commit }) {
    return UserService.users(state.filter, state.page)
      .then((result) => {
        commit('SET_USERS', { users: result.nodes })
        commit('SET_USERS_TOTAL_COUNT', { count: result.totalCount })
      })
  },

  userDelete({ state }, { ids }) {
    return UserService.userDelete(ids)
  },

  userChangeStatus({ state }, { ids, status }) {
    return UserService.userChangeStatus(ids, status)
  }
}
