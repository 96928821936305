// import {martUrl} from "../config";
import store from '../store'

import { HttpLink } from 'apollo-link-http'
// import { setContext } from 'apollo-link-context'
import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloLink, from } from 'apollo-link'
import { onError } from 'apollo-link-error'
import ToastService from '@/service/ToastService'
import router from '@/router'

import config from '../configs'

// const httpLink = new HttpLink({ uri: 'http://localhost:9901/graphql' })
const httpLink = new HttpLink({ uri: config.apiGraphql })

// const authLink = setContext(async (_, { headers }) => {
// get the authentication token from local storage if it exists
// const token = JSON.parse(localStorage.getItem('apollo-token'))
// Return the headers to the context so httpLink can read them

// const token = store?.getters['auth/token']
// if(token) {
//   return {
//     headers: {
//       ...headers,
//       authorization: `Bearer ${token}`
//     }
//   }
// }
// return {}
// })

// const authMiddleware = new ApolloLink((operation, forward) => {
//     const headers = {}
//     const token = store?.getters['auth/token']
//     if(token) {
//         headers.authorization = `Bearer ${token}`
//     }
//     // add the authorization to the headers
//     operation.setContext({
//         headers: headers
//     });
//
//     return forward(operation);
// })

const authLink = new ApolloLink((operation, forward) => {
  const headers = {}
  const token = store?.getters['auth/token']

  if (token) {
    headers.authorization = `Bearer ${token}`
  }
  // add the authorization to the headers
  operation.setContext({
    headers: headers
  })

  return forward(operation)
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    const messages = []

    graphQLErrors.forEach(({ message, locations, path }) => {
      messages.push(message)
      // console.log(
      //   `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      // )
    })

    if (messages.includes('UNAUTHENTICATED')) {
      store?.commit('auth/CLEAR_TOKEN')

      // TODO: REDIRECT TO LOGIN
      console.debug('REDIRECT TO LOGIN')
      router.push({ name: 'auth.signin' }).then()

      return

    } else if (messages.includes('PERMISSION_DENIED')) {
      // TODO: REDIRECT TO FORBIDDEN
      console.debug('REDIRECT TO FORBIDDEN')
      // router.push({ name: 'error.403' }).then()
      router.push({ name: 'auth.signin' }).then()
    } else if (messages.includes('INTERNAL')) {
      // console.log('DISPLAY INTERNAL ERROR')
      ToastService.error('Неустранимая ошибка. Сообщите разработчикам.')
    }
  }
  if (networkError) {
    if (networkError.statusCode === 403) {
      router.push({ name: 'auth.signin' }).then()
    }
    else {
      ToastService.warning('Сервер не доступен. Проверте сетевое подключение.')
      console.warn(`[Network error]: ${networkError}`)
      // TODO: REDIRECT TO NETWORK ERROR PAGE ???
    }
  }
})

const apolloClient = new ApolloClient({
  // link: authLink.concat(httpLink),
  link: from([
    errorLink,
    authLink,
    httpLink
  ]),
  // Provide the URL to the API server.
  // link: new HttpLink({
  //     uri: 'http://localhost:8080/graphql',
  // }),
  // link: createUploadLink({
  //     uri: martUrl('/graphql'),
  //     headers: headers
  // }),
  // Using a cache for blazingly
  // fast subsequent queries.
  cache: new InMemoryCache()
})

export default apolloClient
