// import AclResource from "../models/AclResource";

function page(page) {
  return () => import(/* webpackChunkName: '' */ '../pages/' + page).then((m) => m.default || m)
}

function metaCan(permission) {
  return {
    can: function (to, from, can) {
      return can('access', { type: 'acl', id: permission })
    },
    fail: '/error/403'
  }
}

export default [

  { path: '/home', name: 'home', redirect: { name: 'dashboard' } },
  // { path: '/', name: 'dashboard', component: page('StarterPage.vue') },
  { path: '/', name: 'dashboard', redirect: { name: 'order.list' } },
  { path: '/test', name: 'test', component: page('TestPage.vue'), meta: metaCan('Admin') },
  { path: '/template', name: 'template', component: page('TemplatePage.vue') },
  // Items
  {
    path: '/item/list',
    name: 'item.list',
    component: page('item/ItemListPage.vue'),
    meta: metaCan('Manager')
  },
  {
    path: '/item/view/:id',
    name: 'item.view',
    component: page('item/ItemPage.vue'),
    props: true,
    meta: metaCan('Manager')
  },
  {
    path: '/item/create',
    name: 'item.create',
    component: page('item/ItemCreatePage.vue'),
    meta: metaCan('Admin')
  },
  {
    path: '/item/edit/:id',
    name: 'item.edit',
    component: page('item/ItemEditPage.vue'),
    props: true,
    meta: metaCan('Admin')
  },
  {
    path: '/item-yandex-market/edit/:id',
    name: 'item.yandex_market.edit',
    component: page('item/ItemYandexMarketEditPage.vue'),
    props: true,
    meta: metaCan('Admin')
  },
  {
    path: '/item-category/edit/:id',
    name: 'item-category.edit',
    component: page('item/ItemCategoryEditPage.vue'),
    props: true,
    meta: metaCan('Admin')
  },
  {
    path: '/item-tag/edit/:id',
    name: 'item-tag.edit',
    component: page('item/ItemTagEditPage.vue'),
    props: true,
    meta: metaCan('Admin')
  },
  {
    path: '/item-video/edit/:id',
    name: 'item-video.edit',
    component: page('item/ItemVideoEditPage.vue'),
    props: true,
    meta: metaCan('Admin')
  },
  {
    path: '/item-props/edit/:id',
    name: 'item-props.edit',
    component: page('item/ItemPropsEditPage.vue'),
    props: true,
    meta: metaCan('Admin')
  },
  // Orders
  {
    path: '/order/list',
    name: 'order.list',
    component: page('order/OrderListPage.vue'),
    meta: metaCan('Manager')
  },
  {
    path: '/order/view/:id',
    name: 'order.view',
    component: page('order/OrderPage.vue'),
    props: true,
    meta: metaCan('Manager')
  },
  {
    path: '/order/create',
    name: 'order.create',
    component: page('order/OrderCreatePage.vue'),
    meta: metaCan('Manager')
  },
  {
    path: '/order/edit/:id',
    name: 'order.edit',
    component: page('order/OrderEditPage.vue'),
    props: true,
    meta: metaCan('Manager')
  },
  {
    path: '/order/manage/:id',
    name: 'order.manage',
    component: page('order/OrderManagePage.vue'),
    props: true,
    meta: metaCan('Manager')
  },
  {
    path: '/order/image/edit/:id/:imageId',
    name: 'order.image.edit',
    component: page('order/OrderImageEditPage.vue'),
    props: true,
    meta: metaCan('Manager')
  },
  {
    path: '/order-line/create/:orderId',
    name: 'order-line.create',
    component: page('order/OrderLineCreatePage.vue'),
    props: true,
    meta: metaCan('Manager')
  },
  {
    path: '/order-line/edit/:orderId/:orderLineId',
    name: 'order-line.edit',
    component: page('order/OrderLineEditPage.vue'),
    props: true,
    meta: metaCan('Manager')
  },
  {
    path: '/order-line/edit-item/:orderId/:itemId',
    name: 'order-line.edit-item',
    component: page('order/OrderLineItemEditPage.vue'),
    props: true,
    meta: metaCan('Manager')
  },
  {
    path: '/order-line/create-with-new-item/:orderId',
    name: 'order-line.create-with-new-item',
    component: page('order/OrderLineCreateWithNewItemPage.vue'),
    props: true,
    meta: metaCan('Manager')
  },
  // Calendar
  {
    path: '/order-calendar',
    name: 'order-calendar',
    component: page('calendar/OrderCalendarPage.vue'),
    meta: metaCan('Manager')
  },
  // Shops
  {
    path: '/shop/list',
    name: 'shop.list',
    component: page('shop/ShopListPage.vue'),
    meta: metaCan('Manager')
  },
  {
    path: '/shop/create',
    name: 'shop.create',
    component: page('shop/ShopCreatePage.vue'),
    meta: metaCan('Admin')
  },
  {
    path: '/shop/edit/:id',
    name: 'shop.edit',
    component: page('shop/ShopEditPage.vue'),
    props: true,
    meta: metaCan('Admin')
  },

  // Tags
  {
    path: '/tag/list',
    name: 'tag.list',
    component: page('tag/TagListPage.vue'),
    meta: metaCan('Manager')
  },
  {
    path: '/tag/create',
    name: 'tag.create',
    component: page('tag/TagCreatePage.vue'),
    meta: metaCan('Admin')
  },
  {
    path: '/tag/edit/:id',
    name: 'tag.edit',
    component: page('tag/TagEditPage.vue'),
    props: true,
    meta: metaCan('Admin')
  },

  // Categories
  {
    path: '/category/list',
    name: 'category.list',
    component: page('category/CategoryListPage.vue'),
    meta: metaCan('Manager')
  },
  {
    path: '/category/create',
    name: 'category.create',
    component: page('category/CategoryCreatePage.vue'),
    meta: metaCan('Admin')
  },
  {
    path: '/category/edit/:id',
    name: 'category.edit',
    component: page('category/CategoryEditPage.vue'),
    props: true,
    meta: metaCan('Admin')
  },

  // Props
  {
    path: '/props/list',
    name: 'props.list',
    component: page('props/PropsListPage.vue'),
    meta: metaCan('Manager')
  },
  {
    path: '/props/create',
    name: 'props.create',
    component: page('props/PropsCreatePage.vue'),
    meta: metaCan('Admin')
  },
  {
    path: '/props/edit/:id',
    name: 'props.edit',
    component: page('props/PropsEditPage.vue'),
    props: true,
    meta: metaCan('Admin')
  },

  // Unit of measure
  {
    path: '/uom/list',
    name: 'uom.list',
    component: page('uom/UnitOfMeasureListPage.vue'),
    meta: metaCan('Manager')
  },
  {
    path: '/uom/create',
    name: 'uom.create',
    component: page('uom/UnitOfMeasureCreatePage.vue'),
    meta: metaCan('Admin')
  },
  {
    path: '/uom/edit/:id',
    name: 'uom.edit',
    component: page('uom/UnitOfMeasureEditPage.vue'),
    props: true,
    meta: metaCan('Admin')
  },

  // Profile
  {
    path: '/profile',
    name: 'profile',
    redirect: '/profile/info',
    component: page('profile/ProfilePage.vue'),
    children: [
      { path: 'info', name: 'profile.info', component: page('profile/ProfileInfoPage.vue') },
      { path: 'settings', name: 'profile.settings', component: page('profile/ProfileSettingsPage.vue') }
    ]
  },

  // Users
  {
    path: '/users/list',
    name: 'users.list',
    component: page('users/UsersListPage.vue'),
    meta: metaCan('Manager')
  },
  {
    path: '/users/edit/:userId',
    name: 'users.edit',
    component: page('users/UserEditPage.vue'),
    props: true,
    meta: metaCan('Manager')
  },
  {
    path: '/users/create',
    name: 'users.create',
    component: page('users/UserCreatePage.vue'),
    meta: metaCan('Admin')
  },

  // Settings
  {
    path: '/settings',
    name: 'settings.settings',
    component: page('settings/SettingsPage.vue'),
    meta: metaCan('Admin')
  },

  // Auth
  { path: '/login', name: 'auth.signin', component: page('auth/SigninPage.vue') },
  { path: '/register', name: 'auth.signup', component: page('auth/SignupPage.vue') },
  { path: '/register/complete', name: 'auth.signup-complete', component: page('auth/SignupCompletePage.vue') },

  // Static pages
  { path: '/terms-of-service', name: 'legal.terms-of-service', component: page('legal/TermsOfServicePage.vue') },
  { path: '/privacy-policy', name: 'legal.privacy-policy', component: page('legal/PrivacyPolicyPage.vue') },

  // Errors
  { path: '/error/404', name: 'error.404', component: page('error/NotFoundPage.vue') },
  { path: '/error/403', name: 'error.403', component: page('error/ForbiddenPage.vue') },
  { path: '/error/500', name: 'error.500', component: page('error/ErrorPage.vue') },

  // Export
  {
    path: '/export/yandex-market',
    name: 'export.yandex-market',
    component: page('export/YandexMarketExportPage.vue'),
    meta: metaCan('Manager')
  },
  {
    path: '/export/google-merchant',
    name: 'export.google-merchant',
    component: page('export/GoogleMerchantExportPage.vue'),
    meta: metaCan('Manager')
  },

  // Discounts
  {
    path: '/discount/list',
    name: 'discount.list',
    component: page('discount/DiscountListPage.vue'),
    meta: metaCan('Manager')
  },
  {
    path: '/discount/create',
    name: 'discount.create',
    component: page('discount/DiscountCreatePage.vue'),
    meta: metaCan('Manager')
  },
  {
    path: '/discount/edit/:id',
    name: 'discount.edit',
    component: page('discount/DiscountEditPage.vue'),
    props: true,
    meta: metaCan('Manager')
  },
  {
    path: '/discount-code/list',
    name: 'discount-code.list',
    component: page('discount/DiscountCodeListPage.vue'),
    meta: metaCan('Manager')
  },
  {
    path: '/discount-code/create',
    name: 'discount-code.create',
    component: page('discount/DiscountCodeCreatePage.vue'),
    meta: metaCan('Manager')
  },
  {
    path: '/discount-code/generate',
    name: 'discount-code.generate',
    component: page('discount/DiscountCodeGeneratePage.vue'),
    meta: metaCan('Manager')
  },
  {
    path: '/discount-code/edit/:id',
    name: 'discount-code.edit',
    component: page('discount/DiscountCodeEditPage.vue'),
    props: true,
    meta: metaCan('Manager')
  },

  { path: '*', redirect: { name: 'error.404' } }
]
