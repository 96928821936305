import Page from '@/model/Page'
import DiscountCodeFilter from '@/model/discount/DiscountCodeFilter'
import DiscountCodeService from '@/service/DiscountCodeService'
import DiscountService from '@/service/DiscountService'
import DiscountFilter from '@/model/discount/DiscountFilter'

export const state = {
  items: [],
  filter: DiscountCodeFilter.default(),
  itemsTotalCount: 0,
  page: {
    ...Page.default(),
    size: 30,
    sort: [{ field: 'id', order: 'Desc' }]
  },
  pagesTotal: 0,
  busy: false,
  showAdvancedSearch: false,
  discounts: []
}

// mutations
export const mutations = {
  SET_FILTER(state, { filter }) {
    state.filter = DiscountCodeFilter.create(filter) ?? DiscountCodeFilter.default()
  },
  SET_ITEMS(state, { items }) {
    state.items = [... items]
  },
  SET_ITEMS_TOTAL_COUNT(state, { count }) {
    state.itemsTotalCount = count ?? 0
  },
  SET_PAGE(state, { page }) {
    state.page = Page.create(page)
  },
  SET_BUSY(state, { busy }) {
    state.busy = busy
  },
  TOGGLE_ADVANCED_SEARCH(state) {
    state.showAdvancedSearch = !state.showAdvancedSearch
  },
  SET_DISCOUNTS(state, { discounts }) {
    state.discounts = [... discounts]
  }
}

// getters
export const getters = {}

// actions
export const actions = {
  fetchData({ commit, dispatch }) {
    commit('SET_BUSY', { busy: true })
    Promise.all([dispatch('fetchItems'),dispatch('fetchDiscounts')])
      .finally(() => commit('SET_BUSY', { busy: false }))
  },

  fetchItems({ state, commit }) {
    return DiscountCodeService.find(state.filter, state.page)
      .then((result) => {
        commit('SET_ITEMS', { items: result.nodes })
        commit('SET_ITEMS_TOTAL_COUNT', { count: result.totalCount })
      })
  },

  fetchDiscounts({ state, commit }) {
    const page = Page.create({
      page: 0,
      size: 100000,
      sort: [{ field:'promotion',order:'Asc' }]
    })

    return DiscountService.find(DiscountFilter.default(), page)
      .then((result) => {
        commit('SET_DISCOUNTS', { discounts: result.nodes })
      })
  },

  deleteDiscount({ commit, dispatch }, { ids }) {
    commit('SET_BUSY', { busy: true })

    return DiscountCodeService.delete({ ids })
      .then(() => dispatch('fetchItems'))
      .finally(() => commit('SET_BUSY', { busy: false }))
  }
}
