import OrderService from '@/service/OrderService'

export const state = {
  busy: false,
  orderId: null,
  order: {
    code: null
  },
  orderLineId: null,
  orderLine: {}
}

// mutations
export const mutations = {
  SET_BUSY(state, { busy }) {
    state.busy = busy
  },
  SET_ORDER_ID(state, { orderId }) {
    state.orderId = orderId
  },
  SET_ORDER(state, { order }) {
    state.order = order
  },
  SET_ORDER_LINE_ID(state, { orderLineId }) {
    state.orderLineId = orderLineId
  },
  SET_ORDER_LINE(state, { orderLine }) {
    state.orderLine = orderLine
  }
}

// actions
export const actions = {
  fetchData({ commit, dispatch }, { orderId, orderLineId }) {
    commit('SET_ORDER_ID', { orderId })
    commit('SET_ORDER_LINE_ID', { orderLineId })

    return Promise.all([dispatch('fetchOrder')])
      .finally(() => commit('SET_BUSY', { busy: false }))
  },

  fetchOrder({ state, commit }) {
    return OrderService.findById({ id: state.orderId })
      .then((result) => {
        commit('SET_ORDER', { order: result })
        const line = result?.lines.find((l) => l.id === state.orderLineId)

        if (!line) {
          throw new Error('Строка для редактирования не найдена')
        }

        commit('SET_ORDER_LINE', { orderLine: line })
      })
  },

  updateOrderLine({ state, commit }, { form }) {
    commit('SET_BUSY', { busy: true })

    return OrderService.updateLine({ id: state.orderLineId, form })
      .finally(() => commit('SET_BUSY', { busy: false }))
  }
}
