import gql from 'graphql-tag'
import apolloClient from '@/plugins/apolo-client'
import TagFilter from '@/model/tag/TagFilter'

export default {
  find(filter, page) {
    const QUERY = gql`
      query tags ($filter: TagFilterInput, $page: PageInput) {
          tags (filter: $filter, page: $page) {
              totalCount
              nodes {
                  id
                  name
              }
          }
      }
    `

    return apolloClient.query({
      query: QUERY,
      fetchPolicy: 'network-only',
      variables: {
        filter: filter,
        page: page
      }
    }).then((result) => {
      const { data } = result

      return data.tags
    })
  },

  delete({ ids }) {
    const MUTATION = gql`
      mutation tagDelete ($input: TagDeleteInput) {
          tagDelete (input: $input) {
              ids
          }
      }
    `

    const input = { ids }

    return apolloClient.mutate({
      mutation: MUTATION,
      variables: {
        input: input
      }
    }).then((result) => {
      const { data } = result

      if (data.tagDelete && data.tagDelete.ids) {
        return data.tagDelete.ids
      } else {
        throw new Error('Something wrong!')
      }
    })
  },

  create({ form }) {
    const MUTATION = gql`
      mutation tagCreate ($input: TagCreateInput) {
          tagCreate (input: $input) {
              id
              record {
                  id
                  name
              }
          }
      }
    `

    const input = {
      name: form.name
    }

    return apolloClient.mutate({
      mutation: MUTATION,
      variables: {
        input: input
      }
    }).then((result) => {
      const { data } = result

      if (data.tagCreate && data.tagCreate.record) {
        return data.tagCreate.record
      } else {
        throw new Error('Something wrong!')
      }
    })
  },

  update({ id, form }) {
    const MUTATION = gql`
      mutation tagUpdate ($input: TagUpdateInput) {
          tagUpdate (input: $input) {
              id
              record {
                  id
                  name
              }
          }
      }
    `

    const input = {
      id,
      name: form.name
    }

    return apolloClient.mutate({
      mutation: MUTATION,
      variables: {
        input: input
      }
    }).then((result) => {
      const { data } = result

      if (data.tagUpdate && data.tagUpdate.record) {
        return data.tagUpdate.record
      } else {
        throw new Error('Something wrong!')
      }
    })
  },

  findById({ id }) {
    const QUERY = gql`
      query tags ($filter: TagFilterInput, $page: PageInput) {
          tags (filter: $filter, page: $page) {
              totalCount
              nodes {
                  id
                  name
              }
          }
      }
    `

    const filter = TagFilter.create({ ids: [id] })

    return apolloClient.query({
      query: QUERY,
      fetchPolicy: 'network-only',
      variables: {
        filter
      }
    }).then((result) => {
      const { data } = result

      if (data.tags && data.tags.nodes && data.tags.nodes.length > 0) {
        return data.tags.nodes[0]
      }

      throw Error('Tag [' + id + '] not found')
    })
  }
}
