import gql from 'graphql-tag'
import apolloClient from '@/plugins/apolo-client'

export default {
  updateItemTags({ id, tagIds }) {
    const MUTATION = gql`
      mutation itemSetTags ($input: ItemSetTagsInput) {
        itemSetTags (input: $input) {
          ids
        }
      }
    `

    const input = { id, tagIds }

    return apolloClient.mutate({
      mutation: MUTATION,
      variables: {
        input: input
      }
    }).then((result) => {
      const { data } = result

      if (data.itemSetTags && data.itemSetTags.ids) {
        return data.itemSetTags.ids
      } else {
        throw new Error('Something wrong!')
      }
    })
  }
}
