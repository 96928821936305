export default class CategoryFilter {
  constructor(data) {
    this.ids = data?.ids ?? []
    this.search = data?.search
    this.activity = data?.activity ?? 'All'
  }

  static default() {
    return new CategoryFilter()
  }

  static create(data) {
    return new CategoryFilter(data)
  }
}
