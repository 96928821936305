import gql from 'graphql-tag'
import apolloClient from '@/plugins/apolo-client'
import ItemFilter from '@/model/item/ItemFilter'

export default {
  findItemYandexMarket({ id }) {
    const QUERY = gql`
      query items ($filter: ItemFilterInput, $page: PageInput) {
        items (filter: $filter, page: $page) {
          nodes {
            id
            code
            yandexMarket {
              name
              typePrefix
              vendor
              model
              description
              shortDescription
            }
          }
        }
      }
    `

    const filter = ItemFilter.create({ ids: [id] })

    return apolloClient.query({
      query: QUERY,
      fetchPolicy: 'network-only',
      variables: {
        filter
      }
    }).then((result) => {
      const { data } = result

      if (data.items && data.items.nodes && data.items.nodes.length > 0) {
        return data.items.nodes[0]
      }

      throw Error('Item [' + id + '] not found')
    })
  },

  update({ id, form }) {
    const MUTATION = gql`
      mutation itemYandexMarketUpdate ($input: ItemYandexMarketUpdateInput) {
        itemYandexMarketUpdate (input: $input) {
          id
        }
      }
    `

    const input = {
      ...form,
      id
    }

    return apolloClient.mutate({
      mutation: MUTATION,
      variables: {
        input: input
      }
    }).then((result) => {
      const { data } = result

      if (data.itemYandexMarketUpdate && data.itemYandexMarketUpdate.id) {
        return data.itemYandexMarketUpdate.id
      } else {
        throw new Error('Something wrong!')
      }
    })
  },

  setYandexMarketActive({ ids, isActive }) {
    const MUTATION = gql`
      mutation itemYandexMarketSetActive ($input: ItemYandexMarketSetActiveInput) {
        itemYandexMarketSetActive (input: $input) {
          ids
        }
      }
    `

    const input = { ids, isActive }

    return apolloClient.mutate({
      mutation: MUTATION,
      variables: {
        input: input
      }
    }).then((result) => {
      const { data } = result

      if (data.itemYandexMarketSetActive && data.itemYandexMarketSetActive.ids) {
        return data.itemYandexMarketSetActive.ids
      } else {
        throw new Error('Something wrong!')
      }
    })

  }

}
