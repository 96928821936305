import config from '../configs'

const imageUrl = function (path, type, noCache) {
  if (path) {
    const imageType = type || 'medium'

    let ts = ''

    if (noCache) {
      ts = '?ts=' + Date.now()
    }

    return `${config.cdnUrl}/${path}/${imageType}.jpg` + ts
  }

  return ''
}

const avatarUrl = function (userId, size, hasImage, noCache) {
  if (!!hasImage === true && (typeof userId !== 'undefined') && userId !== '' ) {
    let ts = ''

    if (noCache) {
      ts = '?ts=' + Date.now()
    }

    return `${config.cdnUrl}/user/${userId}/${size}.jpg` + ts
  }

  return '/images/avatars/avatar1.svg'
}

const apiUrl = function(uri) {
  return `${config.apiUrl}/${uri.replace(/^\/+|\/+$/g,'')}`
}

const cdnUrl = function(uri) {
  return `${config.cdnUrl}/${uri.replace(/^\/+|\/+$/g,'')}`
}

export { apiUrl, avatarUrl, imageUrl, cdnUrl }
