import gql from 'graphql-tag'
import apolloClient from '@/plugins/apolo-client'
import UnitOfMeasureFilter from '@/model/uom/UnitOfMeasureFilter'

export default {
  find(filter, page) {
    const QUERY = gql`
      query unitOfMeasures ($filter: UnitOfMeasureFilterInput, $page: PageInput) {
          unitOfMeasures (filter: $filter, page: $page) {
              totalCount
              nodes {
                  id
                  name
              }
          }
      }
    `

    return apolloClient.query({
      query: QUERY,
      fetchPolicy: 'network-only',
      variables: {
        filter: filter,
        page: page
      }
    }).then((result) => {
      const { data } = result

      return data.unitOfMeasures
    })
  },

  delete({ ids }) {
    const MUTATION = gql`
      mutation unitOfMeasureDelete ($input: UnitOfMeasureDeleteInput) {
          unitOfMeasureDelete (input: $input) {
              ids
          }
      }
    `

    const input = { ids }

    return apolloClient.mutate({
      mutation: MUTATION,
      variables: {
        input: input
      }
    }).then((result) => {
      const { data } = result

      if (data.unitOfMeasureDelete && data.unitOfMeasureDelete.ids) {
        return data.unitOfMeasureDelete.ids
      } else {
        throw new Error('Something wrong!')
      }
    })
  },

  create({ form }) {
    const MUTATION = gql`
      mutation unitOfMeasureCreate ($input: UnitOfMeasureCreateInput) {
          unitOfMeasureCreate (input: $input) {
              id
              record {
                  id
                  name
              }
          }
      }
    `

    const input = {
      ...form
    }

    return apolloClient.mutate({
      mutation: MUTATION,
      variables: {
        input: input
      }
    }).then((result) => {
      const { data } = result

      if (data.unitOfMeasureCreate && data.unitOfMeasureCreate.record) {
        return data.unitOfMeasureCreate.record
      } else {
        throw new Error('Something wrong!')
      }
    })
  },

  update({ id, form }) {
    const MUTATION = gql`
      mutation unitOfMeasureUpdate ($input: UnitOfMeasureUpdateInput) {
          unitOfMeasureUpdate (input: $input) {
              id
              record {
                  id
                  name
              }
          }
      }
    `

    const input = {
      ...form,
      id
    }

    return apolloClient.mutate({
      mutation: MUTATION,
      variables: {
        input: input
      }
    }).then((result) => {
      const { data } = result

      if (data.unitOfMeasureUpdate && data.unitOfMeasureUpdate.record) {
        return data.unitOfMeasureUpdate.record
      } else {
        throw new Error('Something wrong!')
      }
    })
  },

  findById({ id }) {
    const QUERY = gql`
      query unitOfMeasures ($filter: UnitOfMeasureFilterInput, $page: PageInput) {
          unitOfMeasures (filter: $filter, page: $page) {
              totalCount
              nodes {
                  id
                  name
              }
          }
      }
    `

    const filter = UnitOfMeasureFilter.create({ ids: [id] })

    return apolloClient.query({
      query: QUERY,
      fetchPolicy: 'network-only',
      variables: {
        filter
      }
    }).then((result) => {
      const { data } = result

      if (data.unitOfMeasures && data.unitOfMeasures.nodes && data.unitOfMeasures.nodes.length > 0) {
        return data.unitOfMeasures.nodes[0]
      }

      throw Error('UnitOfMeasure [' + id + '] not found')
    })
  }
}
