import gql from 'graphql-tag'
import apolloClient from '@/plugins/apolo-client'
import CategoryFilter from '@/model/category/CategoryFilter'

export default {
  find(filter, page) {
    const QUERY = gql`
      query categories ($filter: CategoryFilterInput, $page: PageInput) {
        categories (filter: $filter, page: $page) {
          totalCount
          nodes {
            id
            name
            image
            isActive
            sortWeight
            useForNewOrderItem
            meta {
              isDeleted
              createdAt
              updatedAt
            }
          }
        }
      }
    `

    return apolloClient.query({
      query: QUERY,
      fetchPolicy: 'network-only',
      variables: {
        filter: filter,
        page: page
      }
    }).then((result) => {
      const { data } = result

      return data.categories
    })
  },

  delete({ ids }) {
    const MUTATION = gql`
      mutation categoryDelete ($input: CategoryDeleteInput) {
        categoryDelete (input: $input) {
          ids
        }
      }
    `

    const input = { ids }

    return apolloClient.mutate({
      mutation: MUTATION,
      variables: {
        input: input
      }
    }).then((result) => {
      const { data } = result

      if (data.categoryDelete && data.categoryDelete.ids) {
        return data.categoryDelete.ids
      } else {
        throw new Error('Something wrong!')
      }
    })
  },

  setIsActive({ ids, isActive }) {
    const MUTATION = gql`
      mutation categorySetActive ($input: CategorySetActiveInput) {
        categorySetActive (input: $input) {
          ids
        }
      }
    `

    const input = { ids, isActive }

    return apolloClient.mutate({
      mutation: MUTATION,
      variables: {
        input: input
      }
    }).then((result) => {
      const { data } = result

      if (data.categorySetActive && data.categorySetActive.ids) {
        return data.categorySetActive.ids
      } else {
        throw new Error('Something wrong!')
      }
    })

  },

  create({ form }) {
    const MUTATION = gql`
      mutation categoryCreate ($input: CategoryCreateInput) {
        categoryCreate (input: $input) {
          id
          record {
            id
            name
            image
            isActive
            itemTypePrefix
            meta {
              isDeleted
              createdAt
              updatedAt
            }
          }
        }
      }
    `

    const input = {
      ...form
    }

    return apolloClient.mutate({
      mutation: MUTATION,
      variables: {
        input: input
      }
    }).then((result) => {
      const { data } = result

      if (data.categoryCreate && data.categoryCreate.record) {
        return data.categoryCreate.record
      } else {
        throw new Error('Something wrong!')
      }
    })
  },

  update({ id, form }) {
    const MUTATION = gql`
      mutation categoryUpdate ($input: CategoryUpdateInput) {
        categoryUpdate (input: $input) {
          id
          record {
            id
            name
            image
            isActive
            itemTypePrefix
            meta {
              isDeleted
              createdAt
              updatedAt
            }
          }
        }
      }
    `

    const input = {
      id,
      ...form
    }

    return apolloClient.mutate({
      mutation: MUTATION,
      variables: {
        input: input
      }
    }).then((result) => {
      const { data } = result

      if (data.categoryUpdate && data.categoryUpdate.record) {
        return data.categoryUpdate.record
      } else {
        throw new Error('Something wrong!')
      }
    })
  },

  findById({ id }) {
    const QUERY = gql`
      query categories ($filter: CategoryFilterInput, $page: PageInput) {
        categories (filter: $filter, page: $page) {
          totalCount
          nodes {
            id
            name
            image
            isActive
            itemTypePrefix
            sortWeight
            useForNewOrderItem
            meta {
              isDeleted
              createdAt
              updatedAt
            }
          }
        }
      }
    `

    const filter = CategoryFilter.create({ ids: [id] })

    return apolloClient.query({
      query: QUERY,
      fetchPolicy: 'network-only',
      variables: {
        filter
      }
    }).then((result) => {
      const { data } = result

      if (data.categories && data.categories.nodes && data.categories.nodes.length > 0) {
        return data.categories.nodes[0]
      }

      throw Error('Category [' + id + '] not found')
    })
  }
}
