import Vue from 'vue'

Vue.filter('timeSlot', (value) => {
  if (value) {
    const [l, r] = value.split('_')

    return `${l}:00 - ${r}:00`
  }

  return ''
})
