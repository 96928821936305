import Page from '@/model/Page'
import TagFilter from '@/model/tag/TagFilter'
import TagService from '@/service/TagService'

export const state = {
  items: [],
  filter: TagFilter.default(),
  itemsTotalCount: 0,
  page: {
    ...Page.default(),
    size: 10,
    sort: [{ field: 'name', order: 'Asc' }]
  },
  pagesTotal: 0,
  busy: false
}

// mutations
export const mutations = {
  SET_FILTER(state, { filter }) {
    state.filter = TagFilter.create(filter) ?? TagFilter.default()
  },
  SET_ITEMS(state, { items }) {
    state.items = items
  },
  SET_ITEMS_TOTAL_COUNT(state, { count }) {
    state.itemsTotalCount = count ?? 0
  },
  SET_PAGE(state, { page }) {
    state.page = Page.create(page)
  },
  SET_BUSY(state, { busy }) {
    state.busy = busy
  }
}

// getters
export const getters = {}

// actions
export const actions = {
  fetchData({ commit, dispatch }) {
    commit('SET_BUSY', { busy: true })
    Promise.all([dispatch('fetchTags')])
      .finally(() => commit('SET_BUSY', { busy: false }))
  },
  fetchTags({ state, commit }) {
    return TagService.find(state.filter, state.page)
      .then((result) => {
        commit('SET_ITEMS', { items: result.nodes })
        commit('SET_ITEMS_TOTAL_COUNT', { count: result.totalCount })
      })
  },
  deleteTag({ commit, dispatch }, { ids }) {
    commit('SET_BUSY', { busy: true })

    return TagService.delete({ ids })
      .then(() => dispatch('fetchTags'))
      .finally(() => commit('SET_BUSY', { busy: false }))
  }
}
