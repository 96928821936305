export default class ShopFilter {
  constructor(data) {
    this.ids = data?.ids ?? []
    this.search = data?.search
  }

  static default() {
    return new ShopFilter()
  }

  static create(data) {
    return new ShopFilter(data)
  }
}
