import Page from '@/model/Page'
import OrderService from '@/service/OrderService'
import OrderFilter from '@/model/order/OrderFilter'
import gql from 'graphql-tag'
import { orderStatusList } from '@/utils/OrderStatus'
import apolloClient from '@/plugins/apolo-client'

export const state = {
  items: [],
  calendarType: 'month',
  orderStatusTemplate: null,
  busy: false,
  users: [],
  managerId: null
}

// mutations
export const mutations = {
  SET_ITEMS(state, { items }) {
    state.items = items ?? []
  },
  SET_CALENDAR_TYPE(state, { type }) {
    state.calendarType = type ?? 'month'
  },
  SET_ORDER_STATUS_TEMPLATE(state, { statusTemplate }) {
    state.orderStatusTemplate = statusTemplate
  },
  SET_BUSY(state, { busy }) {
    state.busy = busy
  },
  SET_USERS(state, { users }) {
    state.users = users
  },
  SET_MANAGER_ID(state, { managerId }) {
    state.managerId = managerId ?? null
  }
}

// getters
export const getters = {}

// actions
export const actions = {
  fetchData({ commit, dispatch, state },{ start, end }) {
    const query = gql`
      query orderCalendarPage ($orderFilter: OrderFilterInput,$ordersPage: PageInput,
        $usersFilter: UserFilterInput, $usersPage: PageInput) {
        orders (filter: $orderFilter, page: $ordersPage) {
          totalCount
          nodes {
            id
            code
            orderDate
            status
            deliveryType
            deliveryDate
            deliveryTimeSlot
            customerName
            shippingAt
            manager {
              id
              fullName
            }
            worker {
              id
              fullName
            }
          }
        }
        users (filter: $usersFilter, page: $usersPage) {
          totalCount
          nodes {
            id
            fullName
          }
        }
      }
    `

    let orderStatus = []

    if (state.orderStatusTemplate === 'active') {
      orderStatus = orderStatusList()
        .filter((s) => (s.value !== 'Done' && s.value !== 'Canceled'))
        .map((s) => s.value)
    }
    else if (state.orderStatusTemplate === 'completed') {
      orderStatus = ['Done','Canceled']
    }

    let orderFilter = {
      orderStatus,
      deliveryDate: {
        start,
        end
      }
    }

    if (state.managerId !== null) {
      orderFilter = {
        ...orderFilter,
        managerIds: [state.managerId]
      }
    }
    console.log(orderFilter)
    const ordersPage = { size: 1000, sort: { field: 'deliveryDate', order: 'Asc' }   }

    const usersFilter = { statuses: ['Active'], roles: ['Manager','Admin'] }
    const usersPage = { size: 1000, sort: { field: 'fullName', order: 'Asc' }   }

    return apolloClient.query({
      query,
      fetchPolicy: 'network-only',
      variables: { orderFilter, ordersPage, usersFilter, usersPage }
    })
      .then((result) => {
        const { orders, users } = result.data

        if (orders && orders.nodes && orders.nodes.length > 0) {
          commit('SET_ITEMS', { items: orders.nodes })
        }
        else {
          commit('SET_ITEMS', { items: [] })
        }

        if (users) {
          commit('SET_USERS', { users: users.nodes })
        }

        return orders
      })
      .finally(() => commit('SET_BUSY', { busy: false }))
  }
}
