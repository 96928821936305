export default class ItemFilter {
  constructor(data) {
    this.ids = data?.ids ?? []
    this.codes = data?.codes ?? []
    this.categoryIds = data?.categoryIds ?? []
    this.tagIds = data?.tagIds ?? []
    this.search = data?.search
    this.activity = data?.activity ?? 'All'
    this.yandexMarketActivity = data?.yandexMarketActivity ?? 'All'
    this.withLowPrice = data?.withLowPrice ?? false
    this.isInStock = data?.isInStock ?? null
    this.isPublic = data?.isPublic ?? null
  }

  static default() {
    return new ItemFilter({
      isInStock: true,
      isPublic: true
    })
  }

  static create(data) {
    return new ItemFilter(data)
  }
}
