import gql from 'graphql-tag'
import apolloClient from '@/plugins/apolo-client'
import OrderFilter from '@/model/order/OrderFilter'

export default {
  find(filter, page) {
    const QUERY = gql`
      query orders ($filter: OrderFilterInput, $page: PageInput) {
        orders (filter: $filter, page: $page) {
          totalCount
          nodes {
            id
            code
            orderDate
            status
            customerName
            customerPhone
            customerNotes
            deliveryType
            deliveryShop {
              id
              name
              address
              isActive
            }
            deliveryDate
            deliveryTimeSlot
            recipientName
            recipientPhone
            deliveryAddressCity
            deliveryAddressStreet
            deliveryAddressStreetNumber
            deliveryAddressApartment
            deliveryNotes
            deliveryPrice
            linesAmount
            totalAmount
            lines {
              id
              price
              quantity
              amount
            }
            meta {
              isDeleted
              createdAt
              updatedAt
            }
            manager {
              id
              fullName
            }
          }
        }
      }
    `

    return apolloClient.query({
      query: QUERY,
      fetchPolicy: 'network-only',
      variables: {
        filter: filter,
        page: page
      }
    }).then((result) => {
      const { data } = result

      return data.orders
    })
  },

  findById({ id }) {
    const QUERY = gql`
      query orders ($filter: OrderFilterInput) {
        orders (filter: $filter) {
          totalCount
          nodes {
            id
            code
            orderDate
            status
            customerName
            customerPhone
            customerNotes
            deliveryType
            deliveryShop {
              id
              name
              address
              isActive
            }
            deliveryDate
            deliveryTimeSlot
            recipientName
            recipientPhone
            deliveryAddressCity
            deliveryAddressStreet
            deliveryAddressStreetNumber
            deliveryAddressApartment
            deliveryNotes
            deliveryPrice
            deliveryDiscount
            linesAmount
            totalAmount
            discountCode
            discount {
              id
              promotion
              discountTarget
              dateStart
              dateEnd
              discountValueType
              discountValue
            }
            source
            shippingAt
            createdBy {
              id
              fullName
            }
            manager {
              id
              fullName
            }
            worker {
              id
              fullName
            }
            lines {
              id
              price
              quantity
              amount
              amountDiscount
              lineAmount
              item {
                id
                name
                code
                image
                tags {
                  id
                  name
                }
                width
                height
                length
              }
            }
            images {
              id
              image
              notes
              isPublic
            }
            meta {
              isDeleted
              createdAt
              updatedAt
            }
          }
        }
      }
    `

    const filter = OrderFilter.create({ ids: [id] })

    return apolloClient.query({
      query: QUERY,
      fetchPolicy: 'network-only',
      variables: {
        filter: filter
      }
    }).then((result) => {
      const { data } = result

      if (data.orders && data.orders.nodes && data.orders.nodes.length > 0) {
        return data.orders.nodes[0]
      }

      throw Error('Order [' + id + '] not found')
    })
  },

  setStatus({ ids, orderStatus }) {
    const MUTATION = gql`
      mutation orderSetStatus ($input: OrderSetStatusInput) {
        orderSetStatus (input: $input) {
          ids
        }
      }
    `

    const input = { ids: [... ids], status: orderStatus }

    return apolloClient.mutate({
      mutation: MUTATION,
      variables: {
        input: input
      }
    }).then((result) => {
      const { data } = result

      if (data.orderSetStatus && data.orderSetStatus.ids) {
        return data.orderSetStatus.ids
      } else {
        throw new Error('Something wrong!')
      }
    })
  },

  update({ id, form }) {
    const MUTATION = gql`
      mutation orderUpdate ($input: OrderUpdateInput) {
        orderUpdate(input: $input) {
          id
          record {
            id
          }
        }
      }
    `

    const input = {
      ...form,
      id
    }

    return apolloClient.mutate({
      mutation: MUTATION,
      variables: {
        input: input
      }
    }).then((result) => {
      const { data } = result

      if (data.orderUpdate && data.orderUpdate.record) {
        return data.orderUpdate.record
      } else {
        throw new Error('Something wrong!')
      }
    })
  },

  create({ id, form }) {
    const MUTATION = gql`
      mutation orderCreate ($input: OrderCreateInput) {
        orderCreate(input: $input) {
          id
          record {
            id
          }
        }
      }
    `

    const input = {
      ...form,
      source: 'Manager'
    }

    return apolloClient.mutate({
      mutation: MUTATION,
      variables: {
        input: input
      }
    }).then((result) => {
      const { data } = result

      if (data.orderCreate && data.orderCreate.record) {
        return data.orderCreate.record
      } else {
        throw new Error('Something wrong!')
      }
    })
  },

  delete({ ids }) {
    const MUTATION = gql`
      mutation orderDelete ($input: OrderDeleteInput!) {
        orderDelete (input: $input) {
          ids
        }
      }
    `

    const input = { ids }

    return apolloClient.mutate({
      mutation: MUTATION,
      variables: {
        input: input
      }
    }).then((result) => {
      const { data } = result

      if (data.orderDelete && data.orderDelete.ids) {
        return data.orderDelete.ids
      } else {
        throw new Error('Something wrong!')
      }
    })
  },

  createLine(orderId, { form }) {
    const MUTATION = gql`
      mutation orderLineCreate ($orderId: ID!, $input: OrderLineCreateInput) {
        orderLineCreate(orderId: $orderId, input: $input) {
          id
          record {
            id
          }
        }
      }
    `

    const input = {
      ...form
    }

    return apolloClient.mutate({
      mutation: MUTATION,
      variables: {
        orderId,
        input: input
      }
    }).then((result) => {
      const { data } = result

      if (data.orderLineCreate && data.orderLineCreate.record) {
        return data.orderLineCreate.record
      } else {
        throw new Error('Something wrong!')
      }
    })
  },

  createLineWithNewItem(orderId, { form }) {
    const MUTATION = gql`
      mutation orderLineCreateWithNewItem ($orderId: ID!, $input: OrderLineCreateWithNewItemInput) {
        orderLineCreateWithNewItem(orderId: $orderId, input: $input) {
          id
          record {
            id
          }
        }
      }
    `

    const input = {
      ...form
    }

    return apolloClient.mutate({
      mutation: MUTATION,
      variables: {
        orderId,
        input: input
      }
    }).then((result) => {
      const { data } = result

      if (data.orderLineCreateWithNewItem && data.orderLineCreateWithNewItem.record) {
        return data.orderLineCreateWithNewItem.record
      } else {
        throw new Error('Something wrong!')
      }
    })
  },

  updateLine({ id, form }) {
    const MUTATION = gql`
      mutation orderLineUpdate ($input: OrderLineUpdateInput) {
        orderLineUpdate(input: $input) {
          id
          record {
            id
          }
        }
      }
    `

    const input = {
      id,
      ...form
    }

    return apolloClient.mutate({
      mutation: MUTATION,
      variables: {
        input: input
      }
    }).then((result) => {
      const { data } = result

      if (data.orderLineUpdate && data.orderLineUpdate.record) {
        return data.orderLineUpdate.record
      } else {
        throw new Error('Something wrong!')
      }
    })
  },

  deleteLine({ ids }) {
    const MUTATION = gql`
        mutation orderLineDelete ($input: OrderLineDeleteInput!) {
          orderLineDelete (input: $input) {
            ids
          }
        }
      `

    const input = { ids }

    return apolloClient.mutate({
      mutation: MUTATION,
      variables: {
        input: input
      }
    }).then((result) => {
      const { data } = result

      if (data.orderLineDelete && data.orderLineDelete.ids) {
        return data.orderLineDelete.ids
      } else {
        throw new Error('Something wrong!')
      }
    })
  },

  deleteOrderDiscount({ id }) {
    const MUTATION = gql`
      mutation orderDiscountDelete ($input: OrderDiscountDeleteInput!) {
        orderDiscountDelete (input: $input) {
          id
        }
      }
    `

    const input = { id }

    return apolloClient.mutate({
      mutation: MUTATION,
      variables: {
        input: input
      }
    }).then((result) => {
      const { data } = result

      if (data.orderDiscountDelete && data.orderDiscountDelete.id) {
        return data.orderDiscountDelete.id
      } else {
        throw new Error('Something wrong!')
      }
    })
  },

  addOrderDiscount({ id, code }) {
    const MUTATION = gql`
      mutation orderDiscountAdd ($input: OrderDiscountAddInput) {
        orderDiscountAdd(input: $input) {
          record {
            id
          }
        }
      }
    `

    const input = {
      id,
      discountCode: code
    }

    return apolloClient.mutate({
      mutation: MUTATION,
      variables: {
        input: input
      }
    }).then((result) => {
      const { data } = result

      if (data.orderDiscountAdd && data.orderDiscountAdd.record) {
        return data.orderDiscountAdd.record
      } else {
        throw new Error('Something wrong!')
      }
    })
  },

  manageOrder({ id, form }) {
    const MUTATION = gql`
      mutation OrderManage ($input: OrderManageInput) {
        orderManage(input: $input) {
          id
          record {
            id
          }
        }
      }
    `

    const input = {
      id,
      ...form
    }

    return apolloClient.mutate({
      mutation: MUTATION,
      variables: {
        input: input
      }
    }).then((result) => {
      const { data } = result

      if (data.orderManage && data.orderManage.record) {
        return data.orderManage.record
      } else {
        throw new Error('Something wrong!')
      }
    })
  }

}
