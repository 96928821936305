import vuetify from '@/plugins/vuetify'
import config from '@/configs'
import SettingsService from '@/service/SettingsService'

export const state = {
  themeDarkMode: config.theme.globalTheme,
  settings: {}
}

export const mutations = {
  SET_THEME_DARK_MODE(state, mode) {
    state.themeDarkMode = mode
    localStorage.setItem('themeDarkMode', state.themeDarkMode)
    if (state.themeDarkMode === 'system') {
      vuetify.framework.theme.dark = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
    } else {
      vuetify.framework.theme.dark = state.themeDarkMode === 'dark'
    }
  },
  RESET_APP(state) {
    const themeDarkMode = 'light'

    state.themeDarkMode = themeDarkMode
    localStorage.setItem('themeDarkMode', themeDarkMode)
    vuetify.framework.theme.dark = themeDarkMode === 'dark'
  },
  SET_SETTINGS(state, { settings }) {
    state.settings = settings
  }
}

export const getters = {}

export const actions = {
  loadSettings({ commit }) {
    return SettingsService.load().then((settings) => {
      commit('SET_SETTINGS', { settings })
    })
  },
  updateSettings({ commit, dispatch }, { settings }) {
    return SettingsService.update(settings)
      .then((settings) => {
        return dispatch('loadSettings')
      })
  }
}
