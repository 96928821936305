import OrderService from '@/service/OrderService'
import ItemService from '@/service/ItemService'
import OrderImageService from '@/service/OrderImageService'

export const state = {
  id: null,
  item: {
    code: null,
    lines: [],
    meta: {
      updatedAt: null,
      createdAt: null
    }
  },
  busy: false
}

// mutations
export const mutations = {
  SET_ITEM_ID(state, { id }) {
    state.id = id
  },
  SET_ITEM(state, { item }) {
    state.item = item
  },
  SET_BUSY(state, { busy }) {
    state.busy = busy
  }
}

// getters
export const getters = {
  itemId: (state) => state.id
}

// actions
export const actions = {
  fetchData({ commit, dispatch }, { id }) {
    commit('SET_BUSY', { busy: true })
    commit('SET_ITEM_ID', { id })

    return Promise.all([dispatch('fetchItem')])
      .finally(() => commit('SET_BUSY', { busy: false }))
  },

  fetchItem({ state, commit }) {
    return OrderService.findById({ id: state.id })
      .then((result) => {
        commit('SET_ITEM', { item: result })
      })
  },

  setOrderStatus({ commit, dispatch }, { id, orderStatus }) {
    commit('SET_BUSY', { busy: true })

    return OrderService.setStatus({ ids: [id], orderStatus })
      .then(() => dispatch('fetchItem'))
      .finally(() => commit('SET_BUSY', { busy: false }))
  },

  addDiscount({ state, commit, dispatch },{ code }) {
    commit('SET_BUSY', { busy: true })

    return OrderService.addOrderDiscount({ id: state.id, code })
      .then(() => dispatch('fetchItem'))
      .finally(() => commit('SET_BUSY', { busy: false }))
  },

  deleteDiscount({ state, commit, dispatch }) {
    commit('SET_BUSY', { busy: true })

    return OrderService.deleteOrderDiscount({ id: state.id })
      .then(() => dispatch('fetchItem'))
      .finally(() => commit('SET_BUSY', { busy: false }))
  },

  uploadImage({ state, commit, dispatch }, { file }) {
    commit('SET_BUSY', { busy: true })

    return OrderImageService.uploadImage({ id: state.id, file })
      .then(() => dispatch('fetchItem'))
      .finally(() => commit('SET_BUSY', { busy: false }))
  },

  deleteImages({ commit, dispatch }, { orderImageIds }) {
    commit('SET_BUSY', { busy: true })

    return OrderImageService.deleteImages({ orderImageIds })
      .then(() => dispatch('fetchItem'))
      .finally(() => commit('SET_BUSY', { busy: false }))
  }
}
