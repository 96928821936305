import gql from 'graphql-tag'
import apolloClient from '@/plugins/apolo-client'

export default {
  updateItemProps({ id, props }) {
    const MUTATION = gql`
      mutation itemSetProps ($input: ItemSetPropsInput) {
        itemSetProps (input: $input) {
          ids
        }
      }
    `

    const input = { id, props }

    return apolloClient.mutate({
      mutation: MUTATION,
      variables: {
        input: input
      }
    }).then((result) => {
      const { data } = result

      if (data.itemSetProps && data.itemSetProps.ids) {
        return data.itemSetProps.ids
      } else {
        throw new Error('Something wrong!')
      }
    })
  }
}
