import gql from 'graphql-tag'
import apolloClient from '@/plugins/apolo-client'
import ShopFilter from '@/model/shop/ShopFilter'

export default {
  find(filter, page) {
    const QUERY = gql`
      query shops ($filter: ShopFilterInput, $page: PageInput) {
          shops (filter: $filter, page: $page) {
              totalCount
              nodes {
                  id
                  name
                  address
                  isActive
              }
          }
      }
    `

    return apolloClient.query({
      query: QUERY,
      fetchPolicy: 'network-only',
      variables: {
        filter: filter,
        page: page
      }
    }).then((result) => {
      const { data } = result

      return data.shops
    })
  },

  delete({ ids }) {
    const MUTATION = gql`
      mutation shopDelete ($input: ShopDeleteInput) {
          shopDelete (input: $input) {
              ids
          }
      }
    `

    const input = { ids }

    return apolloClient.mutate({
      mutation: MUTATION,
      variables: {
        input: input
      }
    }).then((result) => {
      const { data } = result

      if (data.shopDelete && data.shopDelete.ids) {
        return data.shopDelete.ids
      } else {
        throw new Error('Something wrong!')
      }
    })
  },

  create({ form }) {
    const MUTATION = gql`
      mutation shopCreate ($input: ShopCreateInput) {
          shopCreate (input: $input) {
              id
              record {
                  id
                  name
                  address
              }
          }
      }
    `

    const input = {
      name: form.name
    }

    return apolloClient.mutate({
      mutation: MUTATION,
      variables: {
        input: input
      }
    }).then((result) => {
      const { data } = result

      if (data.shopCreate && data.shopCreate.record) {
        return data.shopCreate.record
      } else {
        throw new Error('Something wrong!')
      }
    })
  },

  update({ id, form }) {
    const MUTATION = gql`
      mutation shopUpdate ($input: ShopUpdateInput) {
          shopUpdate (input: $input) {
              id
              record {
                  id
                  name
                  address
              }
          }
      }
    `

    const input = {
      id,
      name: form.name,
      address: form.address
    }

    return apolloClient.mutate({
      mutation: MUTATION,
      variables: {
        input: input
      }
    }).then((result) => {
      const { data } = result

      if (data.shopUpdate && data.shopUpdate.record) {
        return data.shopUpdate.record
      } else {
        throw new Error('Something wrong!')
      }
    })
  },

  findById({ id }) {
    const QUERY = gql`
      query shops ($filter: ShopFilterInput, $page: PageInput) {
          shops (filter: $filter, page: $page) {
              totalCount
              nodes {
                  id
                  name
                  address
                  isActive
                  meta {
                    isDeleted
                    createdAt
                    updatedAt
                  }
              }
          }
      }
    `

    const filter = ShopFilter.create({ ids: [id] })

    return apolloClient.query({
      query: QUERY,
      fetchPolicy: 'network-only',
      variables: {
        filter
      }
    }).then((result) => {
      const { data } = result

      if (data.shops && data.shops.nodes && data.shops.nodes.length > 0) {
        return data.shops.nodes[0]
      }

      throw Error('Shop [' + id + '] not found')
    })
  },

  setIsActive({ ids, isActive }) {
    const MUTATION = gql`
      mutation shopSetActive ($input: ShopSetActiveInput) {
        shopSetActive (input: $input) {
          ids
        }
      }
    `

    const input = { ids, isActive }

    return apolloClient.mutate({
      mutation: MUTATION,
      variables: {
        input: input
      }
    }).then((result) => {
      const { data } = result

      if (data.shopSetActive && data.shopSetActive.ids) {
        return data.shopSetActive.ids
      } else {
        throw new Error('Something wrong!')
      }
    })

  }
}
