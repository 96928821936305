import gql from 'graphql-tag'
import apolloClient from '@/plugins/apolo-client'
import UserFilterInput from '@/model/user/UserFilterInput'
import axios from 'axios'
import { apiUrl } from '@/utils/ServiceUrls'

export default {
  users(filter, page) {
    const USERS = gql`
      query users ($filter: UserFilterInput, $page: PageInput) {
          users (filter: $filter, page: $page) {
              totalCount
              nodes {
                  id
                  username
                  email
                  fullName
                  role
                  status
                  hasImage
                  meta {
                      isDeleted
                      createdAt
                      updatedAt
                  }
              }
          }
      }
    `

    return apolloClient.query({
      query: USERS,
      fetchPolicy: 'network-only',
      variables: {
        filter: filter,
        page: page
      }
    }).then((result) => {
      const { data } = result

      return data.users
    })
  },

  userDelete(ids) {
    const DELETE = gql`
      mutation userDelete ($input: UserDeleteInput!) {
          userDelete (input: $input) {
              ids
          }
      }
    `
    const input = {
      ids
    }

    return apolloClient.mutate({
      mutation: DELETE,
      variables: {
        input: input
      }
    }).then((result) => {
      const { data } = result

      if (data.userDelete && data.userDelete.ids) {
        return data.userDelete.ids
      } else {
        throw new Error('Something wrong!')
      }
    })
  },

  userChangeStatus(ids, status) {
    const CHANGE_STATUS = gql`
      mutation userChangeStatus ($input: UserChangeStatusInput!) {
          userChangeStatus (input: $input) {
              ids
          }
      }
    `

    const input = {
      ids,
      status
    }

    return apolloClient.mutate({
      mutation: CHANGE_STATUS,
      variables: {
        input: input
      }
    }).then((result) => {
      const { data } = result

      if (data.userChangeStatus && data.userChangeStatus.ids) {
        return data.userChangeStatus.ids
      } else {
        throw new Error('Something wrong!')
      }
    })
  },

  findUserById(id) {
    const USERS = gql`
      query users ($filter: UserFilterInput, $page: PageInput) {
          users (filter: $filter, page: $page) {
              totalCount
              nodes {
                  id
                  username
                  email
                  fullName
                  role
                  status
                  hasImage
                  meta {
                      isDeleted
                      createdAt
                      updatedAt
                  }
              }
          }
      }
    `

    return apolloClient.query({
      query: USERS,
      fetchPolicy: 'network-only',
      variables: {
        filter: UserFilterInput.create({ ids: [id] }),
        page: null
      }
    }).then((result) => {
      const { data } = result

      if (data.users && data.users.nodes && data.users.nodes.length > 0) {
        return data.users.nodes[0]
      }

      throw Error('User [' + id + '] not found')
    })
  },

  userUpdate(userUpdateForm) {
    const MUTATION = gql`
      mutation userUpdate ($input: UserUpdateInput!) {
        userUpdate (input: $input) {
            id
            record {
                id
                username
                email
                fullName
                role
                status
                hasImage
                meta {
                    isDeleted
                    createdAt
                    updatedAt
                }
            }
        }
    }
    `
    const input = userUpdateForm

    return apolloClient.mutate({
      mutation: MUTATION,
      variables: {
        input: input
      }
    }).then((result) => {
      const { data } = result

      if (data.userUpdate && data.userUpdate.record) {
        return data.userUpdate.record
      } else {
        throw new Error('Something wrong!')
      }
    })
  },

  userCreate(userCreateForm) {
    const MUTATION = gql`
      mutation userCreate ($input: UserCreateInput!) {
          userCreate (input: $input) {
              id
              record {
                  id
                  username
                  email
                  fullName
                  role
                  status
                  hasImage
                  meta {
                      isDeleted
                      createdAt
                      updatedAt
                  }
              }
          }
      }
    `
    const input = userCreateForm

    return apolloClient.mutate({
      mutation: MUTATION,
      variables: {
        input: input
      }
    }).then((result) => {
      const { data } = result

      if (data.userCreate && data.userCreate.record) {
        return data.userCreate.record
      } else {
        throw new Error('Something wrong!')
      }
    })
  },

  userAvatarDelete({ userId }) {
    const MUTATION = gql`
      mutation userAvatarDelete ($input: UserAvatarDeleteInput!) {
          userAvatarDelete (input: $input) {
              id
              record {
                  id
                username
                email
                  fullName
                  role
                  status
                  meta {
                      isDeleted
                      createdAt
                      updatedAt
                  }
                  hasImage
              }
          }
      }
    `
    const input = { id: userId }

    return apolloClient.mutate({
      mutation: MUTATION,
      variables: {
        input: input
      }
    }).then((result) => {
      const { data } = result

      if (data.userAvatarDelete && data.userAvatarDelete.record) {
        return data.userAvatarDelete.record
      } else {
        throw new Error('Something wrong!')
      }
    })
  },

  uploadAvatar({ id, file }) {
    const formData = new FormData()

    formData.append('file', file)

    return axios.post(
      apiUrl(`/api/v1/upload/avatar/${id}`),
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  },

  userChangePassword(userChangePasswordForm) {
    const MUTATION = gql`
      mutation userChangePassword ($input: UserChangePasswordInput!) {
        userChangePassword (input: $input) {
          id
        }
      }
    `
    const input = {
      ... userChangePasswordForm
    }

    return apolloClient.mutate({
      mutation: MUTATION,
      variables: {
        input: input
      }
    }).then((result) => {
      const { data } = result

      if (data.userChangePassword && data.userChangePassword.id) {
        return data.userChangePassword.id
      } else {
        throw new Error('Something wrong!')
      }
    })
  }
}
