import OrderService from '@/service/OrderService'
import ShopService from '@/service/ShopService'
import Page from '@/model/Page'

export const state = {
  id: null,
  item: {},
  shops: [],
  busy: false
}

// mutations
export const mutations = {
  SET_ITEM_ID(state, { id }) {
    state.id = id
  },
  SET_ITEM(state, { item }) {
    state.item = item
  },
  SET_SHOPS(state, { shops }) {
    state.shops = shops
  },
  SET_BUSY(state, { busy }) {
    state.busy = busy
  }
}

// getters
export const getters = {
  itemId: (state) => state.id
}

// actions
export const actions = {
  fetchData({ commit, dispatch }, { id }) {
    commit('SET_BUSY', { busy: true })
    commit('SET_ITEM_ID', { id })

    return Promise.all([dispatch('fetchItem'),dispatch('fetchShops')])
      .finally(() => commit('SET_BUSY', { busy: false }))
  },

  fetchItem({ state, commit }) {
    return OrderService.findById({ id: state.id })
      .then((result) => {
        commit('SET_ITEM', { item: result })
      })
  },

  fetchShops({ state, commit }) {
    return ShopService.find({}, new Page({ size: 20 }))
      .then((result) => {
        commit('SET_SHOPS', { shops: result.nodes })
      })
  },

  updateItem({ state, commit }, { form }) {
    commit('SET_BUSY', { busy: true })

    return OrderService.update({ id: state.id, form })
      .then((result) => {
        commit('SET_ITEM', { item: result })
      })
      .finally(() => commit('SET_BUSY', { busy: false }))
  }
}
