// state
import LoginProfile from '@/model/profile/LoginProfile'
import LoginProfileService from '@/service/LoginProfileService'
import LoginProfileSettingsService from '@/service/LoginProfileSettingsService'
import config from '@/configs'
import ToastService from '@/service/ToastService'
import axios from 'axios'
import UserService from '@/service/UserService'

export const state = {
  profile: null
}

// mutations
export const mutations = {
  SET_PROFILE(state, { profile }) {
    state.profile = LoginProfile.create(profile)
  },
  CLEAR_PROFILE(state) {
    state.profile = null
  }
}

// getters
export const getters = {
  profileId: (state) => state?.profile?.id,
  profile: (state) => state.profile,
  hasProfile: (state) => !!state.profile,
  hasImage: (state) => !!state.profile && state.profile.hasImage,
  settings: (state) => state.profile.settings,
  settingsValue: (state) => (name) => state.profile.settings[name]
}

// actions
export const actions = {
  fetchProfile({ commit, getters }) {
    return LoginProfileService.load()
      .then((profile) => {
        commit('SET_PROFILE', { profile })

        const { settingsValue } = getters
        const adminDarkModeValue = settingsValue('AdminDarkMode')
        let mode = config.theme.globalTheme

        if (['dark', 'light', 'system'].includes(adminDarkModeValue)) {
          mode = adminDarkModeValue
        }

        commit('app/SET_THEME_DARK_MODE', mode, { root: true })
      })
  },

  update({ commit, dispatch }, { data }) {
    return LoginProfileService.update(data)
      .then((profile) => {
        commit('SET_PROFILE', { profile })
      })
  },

  updateSettings({ dispatch }, { settings }) {
    return LoginProfileSettingsService.update(settings)
      .then((settings) => {
        return dispatch('fetchProfile')
      })
  },

  async uploadAvatar({ commit, dispatch }, { file }) {
    return LoginProfileService.uploadAvatar({ file })
  },

  async loginProfileAvatarDelete({ commit, dispatch }) {
    return LoginProfileService.loginProfileAvatarDelete()
      .then((profile) => {
        commit('SET_PROFILE', { profile })
      })
  }
}
