export default class DiscountCodeFilter {
  constructor(data) {
    this.ids = data?.ids ?? []
    this.search = data?.search
    this.discountId = data?.discountId
    this.activity = data?.activity ?? 'All'
  }

  static default() {
    return new DiscountCodeFilter()
  }

  static create(data) {
    return new DiscountCodeFilter(data)
  }
}
