export default class OrderFilter {
  constructor(data) {
    this.ids = data?.ids ?? []
    this.search = data?.search
    this.orderStatus = data?.orderStatus ?? []
    this.deliveryType = data?.deliveryType ?? []
    this.source = data?.source ?? []
    this.managerIds = data?.managerIds ?? []
    this.workerIds = data?.workerIds ?? []
    this.orderDate = data?.orderDate ?? null
    this.deliveryDate = data?.deliveryDate ?? null
  }

  static default() {
    return new OrderFilter()
  }

  static create(data) {
    return new OrderFilter(data)
  }
}
