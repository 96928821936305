import gql from 'graphql-tag'
import apolloClient from '@/plugins/apolo-client'

export default {
  /**
   *
   * @param settings - array of objects {name: string, value: string}
   * @returns {Promise<FetchResult<any>>}
   */
  update(settings) {
    const LOGIN_PROFILE_SETTINGS_UPDATE = gql`
      mutation loginProfileSettingsUpdate ($input: [LoginProfileSettingsUpdateInput!]) {
          loginProfileSettingsUpdate (input: $input) {
              records {
                  name
                  value
              }
          }
      }
    `

    const input = Object.entries(settings).map(([k, v]) => {
      return {
        name: k,
        value: v
      }
    })

    return apolloClient.mutate({
      mutation: LOGIN_PROFILE_SETTINGS_UPDATE,
      variables: {
        input: input
      }
    }).then((result) => {
      const { data } = result

      if (data.loginProfileSettingsUpdate && data.loginProfileSettingsUpdate.records) {

        return data.loginProfileSettingsUpdate.records
      } else {
        throw new Error('Something wrong!')
      }
    })
  }
}
