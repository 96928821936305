import DiscountCodeService from '@/service/DiscountCodeService'
import Page from '@/model/Page'
import DiscountService from '@/service/DiscountService'
import DiscountFilter from '@/model/discount/DiscountFilter'

export const state = {
  busy: false,
  discounts: []
}

// mutations
export const mutations = {
  SET_BUSY(state, { busy }) {
    state.busy = busy
  },
  SET_DISCOUNTS(state, { discounts }) {
    state.discounts = [...discounts]
  }
}

// getters
export const getters = {}

// actions
export const actions = {
  fetchData({ commit, dispatch }) {
    commit('SET_BUSY', { busy: true })
    Promise.all([dispatch('fetchDiscounts')])
      .finally(() => commit('SET_BUSY', { busy: false }))
  },

  fetchDiscounts({ state, commit }) {
    const page = Page.create({
      page: 0,
      size: 100000,
      sort: [{ field: 'promotion', order: 'Asc' }]
    })

    return DiscountService.find(DiscountFilter.default(), page)
      .then((result) => {
        commit('SET_DISCOUNTS', { discounts: result.nodes })
      })
  },

  generateDiscountCodes({ state, commit }, { form }) {
    console.log(form)
    commit('SET_BUSY', { busy: true })

    return DiscountCodeService.generate({ form })
      .finally(() => commit('SET_BUSY', { busy: false }))
  }
}
