export default class UnitOfMeasureFilter {
  constructor(data) {
    this.ids = data?.ids ?? []
    this.search = data?.search
  }

  static default() {
    return new UnitOfMeasureFilter()
  }

  static create(data) {
    return new UnitOfMeasureFilter(data)
  }
}
