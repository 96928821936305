import gql from 'graphql-tag'
import apolloClient from '@/plugins/apolo-client'
import axios from 'axios'
import { apiUrl } from '@/utils/ServiceUrls'

export default {
  login(loginForm) {
    return axios.post(
      apiUrl('/api/v1/login'),
      {
        username: loginForm.username,
        password: loginForm.password
      }
    ).then((result) => {
      console.log('result', result.data)

      const { jwt } = result.data

      if (jwt) {
        return jwt
      }

      throw new Error('Authentication is failed')
    })
  },

  register(registerForm) {
    const REGISTER_USER = gql`
      mutation register ($input: RegisterInput!) {
          register (input: $input) {
              id
              isSuccess
          }
      }
    `
    const input = {
      email: registerForm.email,
      password: registerForm.password,
      firstName: registerForm.firstName,
      lastName: registerForm.lastName
    }

    return apolloClient.mutate({
      mutation: REGISTER_USER,
      variables: {
        input: input
      }
    }).then((result) => {
      const { data } = result

      if (data.register.isSuccess) {
        return true
      } else {
        throw new Error('Registration failed')
      }
    })
  }
}
