import PropsService from '@/service/PropsService'
import UnitOfMeasureService from '@/service/UnitOfMeasureService'

export const state = {
  busy: false,
  unitOfMeasures: []
}

// mutations
export const mutations = {
  SET_UOM(state, { unitOfMeasures }) {
    state.unitOfMeasures = unitOfMeasures
  },
  SET_BUSY(state, { busy }) {
    state.busy = busy
  }
}

// getters
export const getters = {
}

// actions
export const actions = {
  fetchData({ commit, dispatch }) {
    commit('SET_BUSY', { busy: true })

    return Promise.all([dispatch('fetchUnitOfMeasures')])
      .finally(() => commit('SET_BUSY', { busy: false }))
  },
  fetchUnitOfMeasures({ state, commit }) {
    return UnitOfMeasureService.find({}, { page: 0, size: 1000, sort: [{ field: 'name' }] })
      .then((result) => {
        commit('SET_UOM', { unitOfMeasures: result.nodes })
      })
  },
  createProp({ state, commit }, { form }) {
    commit('SET_BUSY', { busy: true })

    return PropsService.create( { form } )
      .finally(() => commit('SET_BUSY', { busy: false }))
  }
}
