import ItemService from '@/service/ItemService'
import PropsService from '@/service/PropsService'
import ItemPropsService from '@/service/ItemPropsService'

export const state = {
  id: null,
  item: {
    code: null
  },
  props: [],
  busy: false
}

// mutations
export const mutations = {
  SET_ITEM_ID(state, { id }) {
    state.id = id
  },
  SET_ITEM(state, { item }) {
    state.item = item
  },
  SET_PROPS(state, { props }) {
    state.props = props
  },
  SET_BUSY(state, { busy }) {
    state.busy = busy
  }
}

// getters
export const getters = {
  itemId: (state) => state.id
}

// actions
export const actions = {
  fetchData({ commit, dispatch }, { id }) {
    commit('SET_BUSY', { busy: true })
    commit('SET_ITEM_ID', { id })

    return Promise.all([dispatch('fetchItem'), dispatch('fetchProps')])
      .finally(() => commit('SET_BUSY', { busy: false }))
  },
  fetchItem({ state, commit }) {
    return ItemService.findById({ id: state.id })
      .then((result) => {
        commit('SET_ITEM', { item: result })
      })
  },
  fetchProps({ state, commit }) {
    return PropsService.find({}, { page: 0, size: 1000, sort: [{ field: 'sortWeight' },{ field: 'name' }] })
      .then((result) => {
        commit('SET_PROPS', { props: result.nodes })
      })
  },
  updateItemProps({ state, commit }, { form }) {
    commit('SET_BUSY', { busy: true })

    return ItemPropsService.updateItemProps({ id: state.id, props: form })
      .finally(() => commit('SET_BUSY', { busy: false }))
  }

}
