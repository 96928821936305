import ItemService from '@/service/ItemService'
import ItemYandexMarketService from '@/service/ItemYandexMarketService'

export const state = {
  id: null,
  item: {
    code: null,
    name: null,
    image: null,
    images: [],
    exportYandexMarket: false,
    yandexMarket: {}
  },
  busy: false
}

// mutations
export const mutations = {
  SET_ITEM_ID(state, { id }) {
    state.id = id
  },
  SET_ITEM(state, { item }) {
    state.item = item
  },
  SET_BUSY(state, { busy }) {
    state.busy = busy
  }
}

// getters
export const getters = {
  itemId: (state) => state.id
}

// actions
export const actions = {
  fetchData({ commit, dispatch }, { id }) {
    commit('SET_BUSY', { busy: true })
    commit('SET_ITEM_ID', { id })

    return Promise.all([dispatch('fetchItem')])
      .finally(() => commit('SET_BUSY', { busy: false }))
  },
  fetchItem({ state, commit }) {
    return ItemService.findById({ id: state.id })
      .then((result) => {
        commit('SET_ITEM', { item: result })
      })
  },

  uploadImage({ commit, dispatch }, { id, file }) {
    commit('SET_BUSY', { busy: true })

    return ItemService.uploadImage({ id, file })
      .then(() => dispatch('fetchItem'))
      .finally(() => commit('SET_BUSY', { busy: false }))
  },

  deleteImages({ commit, dispatch }, { itemImageIds }) {
    commit('SET_BUSY', { busy: true })

    return ItemService.deleteImages({ itemImageIds })
      .then(() => dispatch('fetchItem'))
      .finally(() => commit('SET_BUSY', { busy: false }))
  },

  setMainImage({ commit, dispatch }, { itemImageId }) {
    commit('SET_BUSY', { busy: true })

    return ItemService.setMainImage({ itemImageId })
      .then(() => dispatch('fetchItem'))
      .finally(() => commit('SET_BUSY', { busy: false }))
  },

  setYandexMarketActive({ commit, dispatch }, { isActive }) {
    commit('SET_BUSY', { busy: true })

    const ids = [state.id]

    return ItemYandexMarketService.setYandexMarketActive({ ids, isActive })
      .then(() => dispatch('fetchItem'))
      .finally(() => commit('SET_BUSY', { busy: false }))
  }
}
