import OrderService from '@/service/OrderService'
import apolloClient from '@/plugins/apolo-client'
import gql from 'graphql-tag'

export const state = {
  id: null,
  item: {},
  busy: false
}

// mutations
export const mutations = {
  SET_ITEM_ID(state, { id }) {
    state.id = id
  },
  SET_ITEM(state, { item }) {
    state.item = item
  },
  SET_BUSY(state, { busy }) {
    state.busy = busy
  }
}

// actions
export const actions = {
  fetchData({ commit, dispatch }, { id }) {
    commit('SET_BUSY', { busy: true })
    commit('SET_ITEM_ID', { id })

    const query = gql`
      query orderImageEditPage ($orderFilter: OrderFilterInput) {
        orders (filter: $orderFilter) {
          totalCount
          nodes {
            id
            code
            images {
              id
              image
              notes
              isPublic
            }
          }
        }
      }
    `
    const orderFilter = { ids: [state.id] }

    return apolloClient.query({
      query,
      fetchPolicy: 'network-only',
      variables: { orderFilter }
    }).then((result) => {
      const { orders } = result.data

      if (orders && orders.nodes && orders.nodes.length > 0) {
        commit('SET_ITEM', { item: orders.nodes[0] })
      }
      else {
        throw Error('Order [' + id + '] not found')
      }

      return orders.nodes[0]
    })
      .finally(() => commit('SET_BUSY', { busy: false }))
  },

  updateItem({ state, commit }, { id, notes, isPublic }) {
    commit('SET_BUSY', { busy: true })

    const MUTATION = gql`
      mutation orderImageEditPage ($input: OrderImageUpdateInput) {
        orderImageUpdate (input: $input) {
          id
        }
      }
    `

    const input = { id, notes, isPublic }

    return apolloClient.mutate({
      mutation: MUTATION,
      variables: { input }
    }).then((result) => {
      const { data } = result

      if (data.orderImageUpdate && data.orderImageUpdate.id) {
        return data.orderImageUpdate.id
      } else {
        throw new Error('Something wrong!')
      }
    })
      .finally(() => commit('SET_BUSY', { busy: false }))
  }
}
