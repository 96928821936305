import OrderService from '@/service/OrderService'
import ShopService from '@/service/ShopService'
import Page from '@/model/Page'

export const state = {
  shops: [],
  busy: false
}

// mutations
export const mutations = {
  SET_SHOPS(state, { shops }) {
    state.shops = shops
  },
  SET_BUSY(state, { busy }) {
    state.busy = busy
  }
}

// actions
export const actions = {
  fetchData({ commit, dispatch }) {
    commit('SET_BUSY', { busy: true })

    return Promise.all([dispatch('fetchShops')])
      .finally(() => commit('SET_BUSY', { busy: false }))
  },

  fetchShops({ state, commit }) {
    return ShopService.find({}, new Page({ size: 20 }))
      .then((result) => {
        commit('SET_SHOPS', { shops: result.nodes })
      })
  },

  createOrder({ state, commit }, { form }) {
    commit('SET_BUSY', { busy: true })

    return OrderService.create({ form })
      .then((order) => order )
      .finally(() => commit('SET_BUSY', { busy: false }))
  }
}
