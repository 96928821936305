import Page from '@/model/Page'
import ItemService from '@/service/ItemService'
import ItemFilter from '@/model/item/ItemFilter'
import CategoryService from '@/service/CategoryService'
import CategoryFilter from '@/model/category/CategoryFilter'
import ItemYandexMarketService from '@/service/ItemYandexMarketService'
import TagService from '@/service/TagService'
import TagFilter from '@/model/tag/TagFilter'

export const state = {
  items: [],
  filter: ItemFilter.default(),
  itemsTotalCount: 0,
  page: {
    ...Page.default(),
    size: 30,
    sort: [{ field: 'code', order: 'Desc' }]
  },
  pagesTotal: 0,
  busy: false,
  showAdvancedSearch: false,
  categories: [],
  tags: []
}

// mutations
export const mutations = {
  SET_FILTER(state, { filter }) {
    state.filter = ItemFilter.create(filter) ?? ItemFilter.default()
  },
  SET_ITEMS(state, { items }) {
    state.items = [... items]
  },
  SET_ITEMS_TOTAL_COUNT(state, { count }) {
    state.itemsTotalCount = count ?? 0
  },
  SET_PAGE(state, { page }) {
    state.page = Page.create(page)
  },
  SET_BUSY(state, { busy }) {
    state.busy = busy
  },
  TOGGLE_ADVANCED_SEARCH(state) {
    state.showAdvancedSearch = !state.showAdvancedSearch
  },
  SET_CATEGORIES(state, { categories }) {
    state.categories = [...categories]
  },
  SET_TAGS(state, { tags }) {
    state.tags = [...tags]
  }
}

// getters
export const getters = {}

// actions
export const actions = {
  fetchData({ commit, dispatch }) {
    commit('SET_BUSY', { busy: true })
    Promise.all([dispatch('fetchItems'), dispatch('fetchTags')])
      .finally(() => commit('SET_BUSY', { busy: false }))
  },

  fetchItems({ state, commit }) {
    return ItemService.find(state.filter, state.page)
      .then((result) => {
        commit('SET_ITEMS', { items: result.nodes })
        commit('SET_ITEMS_TOTAL_COUNT', { count: result.totalCount })
      })
  },

  fetchCategories({ state, commit }) {
    const filter = CategoryFilter.default()
    const page = Page.create({ size:10000, sort: [{ field:'name', order: 'Asc' }] })

    return CategoryService.find(filter, page)
      .then((result) => {
        commit('SET_CATEGORIES', { categories: result.nodes })
      })
  },

  fetchTags({ state, commit }) {
    const filter = TagFilter.default()
    const page = Page.create({ size:10000, sort: [{ field:'name', order: 'Asc' }] })

    return TagService.find(filter, page)
      .then((result) => {
        commit('SET_TAGS', { tags: result.nodes })
      })
  },

  deleteItem({ commit, dispatch }, { ids }) {
    commit('SET_BUSY', { busy: true })

    return ItemService.delete({ ids })
      .then(() => dispatch('fetchItems'))
      .finally(() => commit('SET_BUSY', { busy: false }))
  },

  setIsActive({ commit, dispatch }, { ids, isActive }) {
    commit('SET_BUSY', { busy: true })

    return ItemService.setIsActive({ ids, isActive })
      .then(() => dispatch('fetchItems'))
      .finally(() => commit('SET_BUSY', { busy: false }))
  },

  setInStock({ commit, dispatch }, { ids, isInStock }) {
    commit('SET_BUSY', { busy: true })

    return ItemService.setInStock({ ids, isInStock })
      .then(() => dispatch('fetchItems'))
      .finally(() => commit('SET_BUSY', { busy: false }))
  },

  setYandexMarketActive({ commit, dispatch }, { ids, isActive }) {
    commit('SET_BUSY', { busy: true })

    return ItemYandexMarketService.setYandexMarketActive({ ids, isActive })
      .then(() => dispatch('fetchItems'))
      .finally(() => commit('SET_BUSY', { busy: false }))
  }
}
