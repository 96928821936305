import gql from 'graphql-tag'
import apolloClient from '@/plugins/apolo-client'
import axios from 'axios'
import { apiUrl } from '@/utils/ServiceUrls'

export default {
  uploadImage({ id, file }) {
    const formData = new FormData()

    formData.append('files', file)

    return axios.post(
      apiUrl(`/api/v1/upload/order/${id}`),
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  },

  deleteImages({ orderImageIds }) {
    const MUTATION = gql`
      mutation orderImageDelete ($input: OrderImageDeleteInput!) {
        orderImageDelete (input: $input) {
          ids
        }
      }
    `

    const input = { orderImageIds }

    return apolloClient.mutate({
      mutation: MUTATION,
      variables: {
        input: input
      }
    }).then((result) => {
      const { data } = result

      if (data.orderImageDelete && data.orderImageDelete.ids) {
        return data.orderImageDelete.ids
      } else {
        throw new Error('Something wrong!')
      }
    })
  }
}
